import React from 'react'
import ReactDOM from 'react-dom';
import axios from 'axios';
import ReactTable from 'react-table-6'
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom'
import 'react-table-6/react-table.css'
import { array } from 'prop-types';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './styles/navbar.css';
import Modal from "react-bootstrap/Modal";
import moment, { relativeTimeThreshold } from 'moment'
import swal from '@sweetalert/with-react';


export default class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Navbar />
        <Switch>
          <Route path="/" component={DashboardPage} exact={true} />
          <Route path="/people" component={PeoplePage} />
          <Route path="/schedule" component={Calendar} />
          <Route path="/timesheets" component={TimesheetsPage} />
          <Route path="/reports" component={ReportsPage} />
          <Route component={ErrorPage} />
        </Switch>
      </BrowserRouter>
    )
  }
}

//ERROR PAGE COMPONENT
class ErrorPage extends React.Component {
  render() {
    return (
      <div>
        Error page
      </div>
    )
  }
}
//ERROR PAGE COMPONENT
class PeoplePage extends React.Component {
  render() {
    return (
      <div>
        People page
      </div>
    )
  }
}

class ReportsPage extends React.Component {
  render() {
    return (
      <div>
        Reports page
      </div>
    )
  }
}

class TimesheetsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DatabaseData: [],
      clients: [],
      finSupport: [],
      supportServices: [],
      linkedSupportAreas: [],
      linkedSupportService: [],
      linkedFinancialPackages: [],
      clientFin: [],
      servicePrice: 0,
      serviceCost: 0,

      clientId: 0,
      packageId: 0,
      supportAreaId: 0,
      supportServicesId: 0,
      staffId: 0,
      selectedStaffId: 0,
      selectedShiftId: 0,
      weekStart: '',
      weekEnd: '',
      selectedShiftDetails: {},
      financialPackageId: 0,
      staffList: [],
      timesheets: [],
      selectedTimesheetId: [],
      userDetails: {}

    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.editRow = this.editRow.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.setTitle = this.setTitle.bind(this);
    this.getStaff = this.getStaff.bind(this);
    this.showAddModal = this.showAddModal.bind(this);
    this.hideAddModal = this.hideAddModal.bind(this);
    this.hideOpenShiftsModal = this.hideOpenShiftsModal.bind(this);
    this.showOpenShiftsModal = this.showOpenShiftsModal.bind(this);
    this.getClients = this.getClients.bind(this);
    this.deleteShift = this.deleteShift.bind(this);
    this.calculateWeekRange = this.calculateWeekRange.bind(this);
    this.getNextWeek = this.getNextWeek.bind(this);
    this.getPreviousWeek = this.getPreviousWeek.bind(this);
    this.handleEditShift = this.handleEditShift.bind(this);
    this.handleApproveTimesheets = this.handleApproveTimesheets.bind(this);
    this.handleConfirmShift = this.handleConfirmShift.bind(this);
    this.pasteShift = this.pasteShift.bind(this);
    this.getTimesheets = this.getTimesheets.bind(this);
    this.saveAlert = this.saveAlert.bind(this);
    this.deleteAlert = this.deleteAlert.bind(this);
    this.approveAlert = this.approveAlert.bind(this);
    this.extractToken = this.extractToken.bind(this);
    this.handleConfirmCompletedTimesheets = this.handleConfirmCompletedTimesheets.bind(this);
    this.adminConfirmCompletedAlert = this.adminConfirmCompletedAlert.bind(this);


  }

  extractToken() {
    var params = {};
    var parser = document.createElement('a');
    parser.href = window.location.href;
    var query = parser.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      params[pair[0]] = decodeURIComponent(pair[1]);
    }

    return params
  }

  saveAlert() {
    swal("Successifully saved!", " ", "success")
      .then(() => {
        this.hideAddModal();
      })
  }
  adminConfirmCompletedAlert() {
    swal("Admin successifully confirmed!", " ", "success")
      .then(() => {
        this.hideModal();
      })
  }
  approveAlert() {
    swal("Successifully approved!", " ", "success")
      .then(() => {
        this.hideModal();
      })
  }

  deleteAlert() {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          swal("successifully deleted!", {
            icon: "success",
          });
        } else {
          swal("not deleted!");
        }
      })
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.name === 'isGoing' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {

    event.preventDefault();

    const infor = {
      "staffId": Number(this.state.selectedStaffId),
      "location": this.state.address,
      "Date": this.state.date,
      "startTime": this.state.startTime,
      "endTime": this.state.endTime,
      "breaking": this.state.breakMin,
      "notes": this.state.notes,
      "allowance": 0,

    };

    this.state.selectedStaffId = undefined
    this.state.addres = undefined
    this.state.date = undefined
    this.state.startTime = undefined
    this.state.endTime = undefined
    this.state.breakMin = undefined


    axios.post(`https://rosterapi.tarisa.com.au/rlsss/shifts/add?TOCKEN=${this.extractToken().TOCKEN}`, infor)
      .then(res => {
        if (res.status == 201) {
          this.saveAlert();
          this.getTimesheets();
        }
      }).catch(error => {
        swal("Not saved!", " ", "warning")

      })
  }

  handleEditShift(event) {


    const infor = {
      "clientId": Number(this.state.clientId) == 0 ? Number(this.state.selectedShiftDetails.clientId) : Number(this.state.clientId),
      "location": this.state.address == undefined ? this.state.selectedShiftDetails.clientLocation : this.state.address,
      "Date": this.state.date == undefined ? this.state.selectedShiftDate : this.state.date,
      "startTime": this.state.startTime == undefined ? this.state.selectedShiftDetails.startTime : this.state.startTime,
      "endTime": this.state.endTime == undefined ? this.state.selectedShiftDetails.endTime : this.state.endTime,
      "breaking": this.state.breakMin == undefined ? this.state.selectedShiftDetails.breaking : this.state.breakMin,
      "notes": this.state.notes == undefined ? this.state.selectedShiftDetails.notes : this.state.notes,
      "hourlChage": this.state.serviveCost == undefined ? this.state.selectedShiftDetails.hourlChage : this.state.serviceCost,

      "supportAreaId": Number(this.state.supportAreaId) == 0 ? Number(this.state.selectedShiftDetails.supportAreaId) : Number(this.state.supportAreaId),
      "supportServiceId": Number(this.state.supportServicesId) == 0 ? Number(this.state.selectedShiftDetails.supportServiceId) : Number(this.state.supportServicesId),
      "packageId": Number(this.state.financialPackageId) == 0 ? Number(this.state.selectedShiftDetails.financialPackageId) : Number(this.state.financialPackageId)

    };




    axios.post(`https://rosterapi.tarisa.com.au/rlsss/shifts/edit/${this.state.selectedShiftId}?TOCKEN=${this.extractToken().TOCKEN}`, infor)
      .then(res => {

        this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
        this.hideModal();

      }).catch(error => {


      });
  }



  showModal() {
    this.setState({ ShowModal: true });
  }

  hideModal() {
    this.setState({ ShowModal: false });
  }
  showAddModal() {
    this.setState({ ShowAddModal: true });
  }

  hideAddModal() {
    this.setState({ ShowAddModal: false });
  }
  showOpenShiftsModal() {
    this.setState({ ShowOpenShiftsModal: true });
  }

  hideOpenShiftsModal() {
    this.setState({ ShowOpenShiftsModal: false });
  }

  setTitle(event) {

    this.setState({ [event.name]: event.value })



  }


  getTimesheets() {
    var url = `https://rosterapi.tarisa.com.au/rlsss/timeSheets/timeSheets/one?staffId=${this.state.selectedStaffId}&start=${this.state.weekStart}&end=${this.state.weekEnd}&TOCKEN=${this.extractToken().TOCKEN}`;
    axios.get(url).then(timesheet => {
      this.setState({ timesheets: timesheet.data })

    }).catch(e => {

    })
  }


  calculateWeekRange() {
    let now = moment();
    var day = Number(now.weekday())
    var start = now.subtract(day, 'days').format('MM-DD-YYYY');
    var end = now.add(6, 'days').format('MM-DD-YYYY')

    this.setState({ weekStart: start, weekEnd: end })
  }

  getClients() {
    var url1 = `https://rosterapi.tarisa.com.au/rlsss/shifts/clients?TOCKEN=${this.extractToken().TOCKEN}`;
    axios.get(url1).then(client => {
      this.setState({ clients: client.data });
    })

  }

  getFinSupport() {
    var url1 = `https://rosterapi.tarisa.com.au/rlsss/shifts/finsupport?TOCKEN=${this.extractToken().TOCKEN}`;
    axios.get(url1).then(client => {
      this.setState({ finSupport: client.data });
    })

  }

  getSupportServices() {
    var url1 = `https://rosterapi.tarisa.com.au/rlsss/shifts/support-services?TOCKEN=${this.extractToken().TOCKEN}`;
    axios.get(url1).then(client => {
      this.setState({ supportServices: client.data });
    })

  }
  getClientFinancials() {
    var url1 = 'https://rosterapi.tarisa.com.au/rlsss/shifts/clientFin';
    axios.get(url1).then(client => {
      this.setState({ clientFin: client.data });
    })

  }

  deleteShift(selectedShiftId) {
    var url1 = `https://rosterapi.tarisa.com.au/rlsss/shifts/delete/${this.state.selectedShiftId}?TOCKEN=${this.extractToken().TOCKEN}`;
    axios.get(url1).then(client => {
      // this.setState({ clients: client.data });
      this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
      this.hideModal();
    })

  }

  handleApproveTimesheets(selectedShiftId) {
    this.state.selectedTimesheetId.forEach(id => {
      var url1 = `https://rosterapi.tarisa.com.au/rlsss/shifts/approve?shiftId=${id}&staffId=${this.extractToken().STAFF_ID}&TOCKEN=${this.extractToken().TOCKEN}`;
      axios.get(url1).then(client => {
        if (client.status == 200) {
          this.approveAlert();
          this.getTimesheets();
        }
        this.state.selectedTimesheetId = [];
      }).catch(error => {
        swal("Not Approved!", " ", "warning")

      })
    }
    )


  }

  handleConfirmShift(selectedShiftId) {
    var url1 = `https://rosterapi.tarisa.com.au/rlsss/shifts/confirm?shiftId=${this.state.selectedShiftId}&TOCKEN=${this.extractToken().TOCKEN}`;
    axios.get(url1).then(client => {
      // this.setState({ clients: client.data });
      this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
      this.hideModal();
    })

  }
  handleConfirmCompletedTimesheets(selectedShiftId) {
    this.state.selectedTimesheetId.forEach(id => {
      var url1 = `https://rosterapi.tarisa.com.au/rlsss/shifts/adminConfirm?shiftId=${id}&staffId=${this.extractToken().STAFF_ID}&TOCKEN=${this.extractToken().TOCKEN}`;
      axios.get(url1).then(client => {
        if (client.status == 200) {
          this.adminConfirmCompletedAlert();
          this.getTimesheets();
        } else {

        }
        this.state.selectedTimesheetId = [];
      }).catch(error => {
        swal("Not confirmed!", " ", "warning")

      })
    }
    )
  }

  getNextWeek() {
    var start = moment(this.state.weekEnd).format('MM-DD-YYYY');
    start = moment(start).add(1, 'days').format('MM-DD-YYYY');
    var end = moment(start).add(6, 'days').format('MM-DD-YYYY')

    var url = `https://rosterapi.tarisa.com.au/rlsss/timeSheets/timeSheets/one?staffId=${this.state.selectedStaffId}&start=${start}&end=${end}&TOCKEN=${this.extractToken().TOCKEN}`;
    axios.get(url).then(timesheet => {
      this.setState({ timesheets: timesheet.data })

    }).catch(e => {

    })
    this.setState({ weekStart: start, weekEnd: end })

  }

  getPreviousWeek() {
    var end = moment(this.state.weekStart).format('MM-DD-YYYY');
    end = moment(end).subtract(1, 'days').format('MM-DD-YYYY');
    var start = moment(end).subtract(6, 'days').format('MM-DD-YYYY');

    var url = `https://rosterapi.tarisa.com.au/rlsss/timeSheets/timeSheets/one?staffId=${this.state.selectedStaffId}&start=${start}&end=${end}&TOCKEN=${this.extractToken().TOCKEN}`;
    axios.get(url).then(timesheet => {
      this.setState({ timesheets: timesheet.data })

    }).catch(e => {

    })

    this.setState({ weekStart: start, weekEnd: end })

  }

  pasteShift() {


    const infor = {
      "staffId": Number(this.state.selectedStaffId),
      "clientId": Number(this.state.selectedShiftDetails.clientId),
      "location": this.state.selectedShiftDetails.clientLocation,
      "Date": this.state.date,
      "startTime": this.state.selectedShiftDetails.startTime,
      "endTime": this.state.selectedShiftDetails.endTime,
      "breaking": this.state.selectedShiftDetails.breaking,
      "notes": this.state.selectedShiftDetails.notes,
      "allowance": 0
    };




    axios.post(`https://rosterapi.tarisa.com.au/rlsss/shifts/add?TOCKEN=${this.extractToken().TOCKEN}`, infor)
      .then(res => {

        this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
        this.hideModal();

      }).catch(error => {


      });
  }

  componentDidMount() {
    this.extractToken();
    this.getStaff();
    // this.getShifts();
    this.getClients();




    this.calculateWeekRange();
  }

  getStaff() {

    //FETCHING ALL STAFF FROM API
    var url1 = `https://rosterapi.tarisa.com.au/rlsss/shifts/staff?TOCKEN=${this.extractToken().TOCKEN}`;
    axios.get(url1).then(staff => {

      this.setState({ staffList: staff.data });


    }).catch(error => {

    })
  }

  editRow(props) {

  }


  render() {

    const columns = [{
      Header: () => (
        <button className="btn text-white  btn-warning btn-block" type="button">
          &#10003;
        </button>
      ),
      maxWidth: 50,
      Cell: row => {

        return (
          <div
            onClick={() => {
              var arr = this.state.selectedTimesheetId;
              if (!arr.includes(row.original.shiftId)) {
                arr = arr.concat(row.original.shiftId)
                this.setState({ selectedTimesheetId: arr })
              }


            }
            }
          >
            <input

              type="checkbox" className="ml-2">
            </input>
          </div >
        )
      }

    }, {
      Header: () => (
        <button className="btn text-white  btn-warning btn-block" type="button">
          Date
        </button>
      ),
      Cell: row => {
        var date = row.original.date;
        date = moment(date).format('llll');
        date = date.split(" ");
        return (
          <div>
            {
              date[0] + " " + date[1] + " " + date[2] + " " + date[3]
            }
          </div>
        )
      }

    }, {
      Header: () => (
        <button className="btn text-white  btn-warning btn-block" type="button">
          Start time
        </button>
      ),
      accessor: 'startTime',
      minWidth: 60

    }, {
      Header: () => (
        <button className="btn text-white  btn-warning btn-block" type="button">
          End time
        </button>
      ),
      accessor: 'endTime',
      minWidth: 60
    }, {

      Header: () => (
        <button className="btn text-white  btn-warning btn-block" type="button">
          Location
        </button>
      ),
      accessor: 'location'
    }, {

      Header: () => (
        <button className="btn text-white  btn-warning btn-block" type="button">
          Duration
        </button>
      ),
      accessor: 'duration',
      minWidth: 60
    }, {

      Header: () => (
        <button className="btn text-white  btn-warning btn-block" type="button">
          Pay rate
        </button>
      ),
      accessor: 'payRate',
      minWidth: 60
    }, {

      Header: () => (
        <button className="btn text-white  btn-warning btn-block" type="button">
          Allowances
        </button>
      ),
      accessor: 'allowance',
      minWidth: 70
    }, {

      Header: () => (
        <button className="btn text-white  btn-warning btn-block" type="button">
          Status
        </button>
      ),
      minWidth: 150,
      Cell: row => {

        return (
          <div>
            {
              row.original.isApproved ? <a className="label label-info"><small><span className="badge badge-success ml-2">Approved</span></small></a> : <a className="label label-info"><small><span className="badge badge-danger ml-2">Not approved</span></small></a>
            }
            {
              row.original.isCompleted ? <a className="label label-info"><small><span className="badge badge-warning ml-2">Completed</span></small></a> : <a className="label label-info"><small><span className="badge badge-danger ml-2">Uncompleted</span></small></a>
            }
            {
              row.original.isAdminConfirmed ? <a className="label label-info"><small><span className="badge badge-info ml-2">Admin confirmed</span></small></a> : <a className="label label-info"><small><span className="badge badge-danger ml-2">Unconfirmed</span></small></a>
            }
          </div>
        )
      }
    }]

    return (
      <div>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 ml-2 mr-2">
          <h1 className="h2">Timesheets</h1>
          <div className="btn-toolbar mb-2 mb-md-0">
            <div className="btn-group mr-2">
              <button onClick={this.getPreviousWeek} type="button" className="btn btn-sm btn-outline-secondary">&#10094;</button>
              <button type="button" className="btn btn-sm btn-outline-secondary">
                <span data-feather="calendar"></span>{moment(this.state.weekStart).format('DD-MM-YYYY')} - {moment(this.state.weekEnd).format('DD-MM-YYYY')}</button>
              <button onClick={this.getNextWeek} type="button" className="btn btn-sm btn-outline-secondary">&#10095;</button>
            </div>

            <button
              onClick={this.showAddModal}
              type="button"
              className="btn btn-sm btn-outline-secondary mr-2"
            >
              &#43; Add timesheet
          </button>
            <a href={`https://rosterapi.tarisa.com.au/rlsss/timeSheets/excelExport?start=${this.state.weekStart}&end=${this.state.weekEnd}&TOCKEN=${this.extractToken().TOCKEN}`}
              type="button"
              className="btn btn-sm btn-outline-secondary mr-2"
            > &#8645; Export timesheet</a>
            <button
              onClick={this.handleApproveTimesheets}
              type="button"
              className="btn btn-sm btn-outline-secondary mr-2">
              &#10003; Approve selected timesheets
          </button>
            <button
              onClick={this.handleConfirmCompletedTimesheets}
              type="button"
              className="btn btn-sm btn-outline-secondary mr-2">
              &#10003; Confirm completed timesheets
          </button>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-3" >
            <form >
              <input className="form-control ml-1" type="text" placeholder="Search" />
            </form>
            <div style={{ height: "450px", overflowY: "scroll" }}>
              {
                this.state.staffList.map((staff) =>
                  <button
                    onMouseOver={() => {
                      this.setState({ selectedStaffId: staff.id })


                    }}
                    onClick={() => {
                      this.getTimesheets(this.state.selectedStaffId)

                    }}
                    className="btn text-white btn-warning btn-block mt-2 ml-1"
                    type="button"
                    key={staff.id}
                  >
                    {`${staff.firstname} ${staff.lastname}`}
                  </button>
                )
              }
            </div>
          </div>
          <div className="col-md-9">
            <ReactTable
              data={this.state.timesheets}
              columns={columns}
              defaultPageSize={10}
            />
          </div>
          {/* ADD SHIFT MODAL */}
          <Modal show={this.state.ShowAddModal} onHide={true} size="lg" >
            <form onSubmit={this.handleSubmit}>
              <Modal.Header>
                <Modal.Title><h5 className="modal-title" >Add shift on {this.state.selectedShiftDate}</h5></Modal.Title>
                <button type="button" className="close" onClick={this.hideAddModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </Modal.Header>
              <Modal.Body>
                <div className="row">

                  <div className="col-md-12">

                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label htmlFor="inputEmail4">Who is working?</label>
                        <select
                          required
                          onClick={(event) => {
                            var staffId = event.target.value;
                            this.setState({ selectedStaffId: staffId })


                          }}
                          className="form-control"
                          name="selectedStaffId" >
                          {
                            this.state.staffList.map((staff) =>
                              <option
                                value={staff.id}
                                key={staff.id}
                              >
                                {`${staff.firstname} ${staff.lastname}`}
                              </option>
                            )
                          }
                        </select>

                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="inputAddress">Location</label>
                        <input required type="text" className="form-control" name="address" onChange={event => this.setTitle(event.target)} placeholder="Location" />

                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="inputAddress">Date</label>
                        <input required type="date" className="form-control" name="date" onChange={event => this.setTitle(event.target)} />

                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label htmlFor="inputCity">Start time</label>
                        <input required type="time" className="form-control" onChange={event => this.setTitle(event.target)} name="startTime" />

                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="inputCity">End time</label>
                        <input required type="time" className="form-control" onChange={event => this.setTitle(event.target)} name="endTime" />

                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="inputCity">Break(min)</label>
                        <input type="number" className="form-control" onChange={event => this.setTitle(event.target)} name="breakMin" />

                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleFormControlTextarea1">Notes</label>
                      <textarea  className="form-control" onChange={event => this.setTitle(event.target)} name="notes" rows="3"></textarea>

                    </div>

                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-info"
                  type="Submit"
                >Save shift</button>
              </Modal.Footer>
            </form>
          </Modal>
        </div>

      </div >
    )
  }
}

{/* NAVBAR COMPONENT */ }
class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      userDetails: {}
    };


    this.extractToken = this.extractToken.bind(this);

  }

  extractToken() {
    var params = {};
    var parser = document.createElement('a');
    parser.href = window.location.href;
    var query = parser.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      params[pair[0]] = decodeURIComponent(pair[1]);
    }


    return params
  }

  componentDidMount() {
    this.extractToken();
  }

  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-md navbar-dark bg-warning">
          <Link className="navbar-brand" to="/">Hivelink Roster</Link>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarsExample04">
            <ul className="navbar-nav mr-auto">
              {
                true ? <li className="nav-item">
                  <a className="nav-link" href="https://rlsss.tarisa.com.au/">Home</a>
                </li> : ""
              }
              {
                true ? <li className="nav-item">
                  <Link className="nav-link" to={`/?TOCKEN=${this.extractToken().TOCKEN}&STAFF_ID=${this.extractToken().STAFF_ID}&ACCESS_LEVEL=${this.extractToken().ACCESS_LEVEL}`}>Dashboard</Link>
                </li> : ""
              }
              {
                this.extractToken().ACCESS_LEVEL == 1 ? <li className="nav-item">
                  <Link className="nav-link" to={`/schedule?TOCKEN=${this.extractToken().TOCKEN}&STAFF_ID=${this.extractToken().STAFF_ID}&ACCESS_LEVEL=${this.extractToken().ACCESS_LEVEL}`} >Schedule</Link>
                </li> : ""
              }

              {
                this.extractToken().ACCESS_LEVEL == 1 ? <li className="nav-item">
                  <Link className="nav-link" to={`/timesheets?TOCKEN=${this.extractToken().TOCKEN}&STAFF_ID=${this.extractToken().STAFF_ID}&ACCESS_LEVEL=${this.extractToken().ACCESS_LEVEL}`}>Timesheets</Link>
                </li> : ""
              }

              {/* <li className="nav-item">
                <Link className="nav-link" to="/reports">Reports</Link>
              </li> */}

            </ul>
          </div>
        </nav>
      </div >
    )
  }
}

{/* CALENDAR COMPONENT */ }
class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DatabaseData: [],
      clients: [],
      finSupport: [],
      supportServices: [],
      linkedSupportAreas: [],
      linkedSupportService: [],
      linkedFinancialPackages: [],
      clientFin: [],
      servicePrice: 0,
      serviceCost: 0,

      clientId: 0,
      packageId: 0,
      supportAreaId: 0,
      supportServicesId: 0,
      staffId: 0,
      selectedStaffId: 0,
      selectedShiftId: 0,
      weekStart: '',
      weekEnd: '',
      selectedShiftDetails: {},
      financialPackageId: 0,
      allowance: 0,
      userDetails: {},
      totalShifts: {},
      copiedWeekShifts: [],
      getNextWeeks: [],

    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.getShifts = this.getShifts.bind(this);
    this.editRow = this.editRow.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.setTitle = this.setTitle.bind(this);
    this.getStaff = this.getStaff.bind(this);
    this.showAddModal = this.showAddModal.bind(this);
    this.hideAddModal = this.hideAddModal.bind(this);
    this.hideOpenShiftsModal = this.hideOpenShiftsModal.bind(this);
    this.showOpenShiftsModal = this.showOpenShiftsModal.bind(this);
    this.getClients = this.getClients.bind(this);
    this.getFinSupport = this.getFinSupport.bind(this);
    this.getSupportServices = this.getSupportServices.bind(this);
    this.getClientFinancials = this.getClientFinancials.bind(this);
    this.deleteShift = this.deleteShift.bind(this);
    this.calculateWeekRange = this.calculateWeekRange.bind(this);
    this.getNextWeek = this.getNextWeek.bind(this);
    this.getPreviousWeek = this.getPreviousWeek.bind(this);
    this.handleEditShift = this.handleEditShift.bind(this);
    this.handleApproveShift = this.handleApproveShift.bind(this);
    this.handleConfirmShift = this.handleConfirmShift.bind(this);
    this.pasteShift = this.pasteShift.bind(this);
    this.deleteAlert = this.deleteAlert.bind(this);
    this.saveAlert = this.saveAlert.bind(this);
    this.deleteSaveAlert = this.deleteSaveAlert.bind(this);
    this.approveAlert = this.approveAlert.bind(this);
    this.extractToken = this.extractToken.bind(this);
    this.countShifts = this.countShifts.bind(this);
    this.calculatePasteWeek = this.calculatePasteWeek.bind(this);
    this.pasteWeek = this.pasteWeek.bind(this);
    this.showCopyShiftsModal = this.showCopyShiftsModal.bind(this);
    this.hideCopyShiftsModal = this.hideCopyShiftsModal.bind(this);
    this.weekCopyAlert = this.weekCopyAlert.bind(this);
    this.handleWeekSubmit = this.handleWeekSubmit.bind(this);

  }

  extractToken() {
    var params = {};
    var parser = document.createElement('a');
    parser.href = window.location.href;
    var query = parser.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      params[pair[0]] = decodeURIComponent(pair[1]);
    }

    return params
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.name === 'isGoing' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {

    event.preventDefault();

    const infor = {
      "staffId": Number(this.state.selectedStaffId),
      "location": this.state.address,
      "Date": this.state.selectedShiftDate,
      "startTime": this.state.startTime,
      "endTime": this.state.endTime,
      "breaking": this.state.breakMin,
      "notes": this.state.notes,
      "allowance": 0

    };

    if (Number(this.state.selectedStaffId) == -1) {
      axios.post(`https://rosterapi.tarisa.com.au/rlsss/shifts/add2?TOCKEN=${this.extractToken().TOCKEN}`, infor)
        .then(res => {
          if (res.status == 201) {
            this.saveAlert();
            this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
          }

        }).catch(error => {


        });
    } else {
      axios.post(`https://rosterapi.tarisa.com.au/rlsss/shifts/add?TOCKEN=${this.extractToken().TOCKEN}`, infor)
        .then(res => {
          if (res.status == 201) {
            this.saveAlert();
            this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
          }

        }).catch(error => {
          swal("Not saved!", " ", "warning")

        })
    }


  }

  handleEditShift(event) {


    const infor = {
      "location": this.state.address == undefined ? this.state.selectedShiftDetails.clientLocation : this.state.address,
      "Date": this.state.date == undefined ? this.state.selectedShiftDate : this.state.date,
      "startTime": this.state.startTime == undefined ? this.state.selectedShiftDetails.startTime : this.state.startTime,
      "endTime": this.state.endTime == undefined ? this.state.selectedShiftDetails.endTime : this.state.endTime,
      "breaking": this.state.breakMin == undefined ? this.state.selectedShiftDetails.breaking : this.state.breakMin,
      "notes": this.state.notes == undefined ? this.state.selectedShiftDetails.notes : this.state.notes,


    };




    axios.post(`https://rosterapi.tarisa.com.au/rlsss/shifts/edit/${this.state.selectedShiftId}?TOCKEN=${this.extractToken().TOCKEN}`, infor)
      .then(res => {
        if (res.status == 200) {
          this.saveAlert();
          this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
        }


      }).catch(error => {
        swal("Not edited!", " ", "warning")

      })
  }



  showModal() {
    this.setState({ ShowModal: true });
  }

  hideModal() {
    this.setState({ ShowModal: false });
  }
  showAddModal() {
    this.setState({ ShowAddModal: true });
  }
  showCopyShiftsModal() {
    this.setState({ ShowCopyShiftsModal: true });
  }

  hideCopyShiftsModal() {
    this.setState({ ShowCopyShiftsModal: false });
  }
  hideAddModal() {
    this.setState({ ShowAddModal: false });
  }
  showOpenShiftsModal() {
    this.setState({ ShowOpenShiftsModal: true });
  }

  hideOpenShiftsModal() {
    this.setState({ ShowOpenShiftsModal: false });
  }

  setTitle(event) {

    this.setState({ [event.name]: event.value })


  }

  //AN HTTP REQUEST TO THE getAllShifts-endpoint
  getShifts(event) {
    var url1 = `https://rosterapi.tarisa.com.au/rlsss/shifts/all?start=${event.start}&end=${event.end}&TOCKEN=${this.extractToken().TOCKEN}`;
    axios.get(url1).then(shifts => {
      //FETCHING ALL STAFF FROM API
      url1 = `https://rosterapi.tarisa.com.au/rlsss/shifts/staff?TOCKEN=${this.extractToken().TOCKEN}`;
      axios.get(url1).then(staff => {
        var shiftsList = [];
        var staffList = [];
        var finalData = [];
        shiftsList = shifts.data;
        staffList = staff.data;
        url1 = `https://rosterapi.tarisa.com.au/rlsss/shifts/all/openShifts?start=${event.start}&end=${event.end}&TOCKEN=${this.extractToken().TOCKEN}`;
        axios.get(url1).then(openShift => {

          var open = [];
          var openShiftsList = openShift.data;
          openShiftsList.forEach(shift => {
            var A = [];
            A = shift.dayShifts;
            A.forEach(data => {
              open.push(data);
            });

          });
          finalData.push({ staffId: -1, staffName: 'Open Shifts', shifts: open });
          staffList.forEach(element => {
            var staffId = element.id;
            var staffName = element.firstname + ' ' + element.lastname;
            var day;
            var list = [];
            shiftsList.forEach(shift => {
              day = shift.day;
              var A = [];
              A = shift.dayShifts;
              A.forEach(data => {
                if (data.staffId == staffId) {
                  list.push(data);
                }
              });

            });
            finalData.push({ staffId: staffId, staffName: staffName, day: day, shifts: list });
            list = [];
          });
          this.setState({ DatabaseData: finalData, loading: false })
          this.countShifts(finalData);
          return finalData
        }).catch(e => {

        })
      }).catch(error => {

      })
    }).catch(error => {

    })



  }

  handleWeekSubmit() {
    var url1 = `https://rosterapi.tarisa.com.au/rlsss/shifts/copyPaste?start=${this.state.weekStart}&end=${this.state.weekEnd}&TOCKEN=${this.extractToken().TOCKEN}`;
    axios.post(url1, this.state.copiedWeekShifts).then(client => {
      if (client.status == 201) {
        this.weekCopyAlert();
      }
    }).catch(error => {
      swal("Not pasted!", " ", "warning")
    })
    this.setState({ copiedWeekShifts: [] })
  }

  countShifts(event) {
    var position = 0;
    var shifts = 0;
    var approved = 0;
    var completed = 0;
    var adminApproved = 0;
    event.forEach(element => {

      if (position != 0) {
        element.shifts.forEach(element2 => {
          shifts++
          if (element2.isApproved == true) {
            approved++
          }
          if (element2.isCompleted == true) {
            completed++
          }
          if (element2.isAdminConfirmed == true) {
            adminApproved++
          }
        })
      }

      this.setState({ totalShifts: { totalShifts: shifts, totalApproved: approved, totalAdminConfirmed: adminApproved, totalCompleted: completed } })

      position = position + 1;
    })

  }


  calculateWeekRange() {
    let now = moment();
    var day = Number(now.weekday())
    var start = now.subtract(day, 'days').format('MM-DD-YYYY');
    var end = now.add(6, 'days').format('MM-DD-YYYY')

    this.getShifts({ start: start, end: end })
    this.setState({ weekStart: start, weekEnd: end })
  }

  getClients() {
    var url1 = `https://rosterapi.tarisa.com.au/rlsss/shifts/clients?TOCKEN=${this.extractToken().TOCKEN}`;
    axios.get(url1).then(client => {
      this.setState({ clients: client.data });
    })

  }

  getFinSupport() {
    var url1 = `https://rosterapi.tarisa.com.au/rlsss/shifts/finsupport?TOCKEN=${this.extractToken().TOCKEN}`;
    axios.get(url1).then(client => {
      this.setState({ finSupport: client.data });
    })

  }

  getSupportServices() {
    var url1 = 'https://rosterapi.tarisa.com.au/rlsss/shifts/support-services';
    axios.get(url1).then(client => {
      this.setState({ supportServices: client.data });
    })

  }
  getClientFinancials() {
    var url1 = 'https://rosterapi.tarisa.com.au/rlsss/shifts/clientFin';
    axios.get(url1).then(client => {
      this.setState({ clientFin: client.data });
    })

  }

  deleteShift(selectedShiftId) {
    var url1 = `https://rosterapi.tarisa.com.au/rlsss/shifts/delete/${this.state.selectedShiftId}?TOCKEN=${this.extractToken().TOCKEN}`;
    axios.get(url1).then(client => {
      if (client.status == 200) {
        this.deleteSaveAlert();
        this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
      }

    }).catch(error => {
      swal("Not deleted!", " ", "warning")
    })
  }

  handleApproveShift(selectedShiftId) {
    var url1 = `https://rosterapi.tarisa.com.au/rlsss/shifts/approve?shiftId=${this.state.selectedShiftId}&staffId=${this.extractToken().STAFF_ID}&TOCKEN=${this.extractToken().TOCKEN}`;
    axios.get(url1).then(client => {
      if (client.status == 200) {
        this.approveAlert();
        this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
      }
    }).catch(error => {
      swal("Not approved!", " ", "warning")

    })

  }

  handleConfirmShift(selectedShiftId) {
    var url1 = `https://rosterapi.tarisa.com.au/rlsss/shifts/confirm?shiftId=${this.state.selectedShiftId}&TOCKEN=${this.extractToken().TOCKEN}`;
    axios.get(url1).then(client => {
      // this.setState({ clients: client.data });
      this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
      this.hideModal();
    })

  }

  getNextWeek() {
    var start = moment(this.state.weekEnd).format('MM-DD-YYYY');
    start = moment(start).add(1, 'days').format('MM-DD-YYYY');
    var end = moment(start).add(6, 'days').format('MM-DD-YYYY')

    this.getShifts({ start: start, end: end })
    this.setState({ weekStart: start, weekEnd: end })
  }

  getPreviousWeek() {
    var end = moment(this.state.weekStart).format('MM-DD-YYYY');
    end = moment(end).subtract(1, 'days').format('MM-DD-YYYY');
    var start = moment(end).subtract(6, 'days').format('MM-DD-YYYY');

    this.getShifts({ start: start, end: end })
    this.setState({ weekStart: start, weekEnd: end })
  }

  pasteShift() {


    const infor = {
      "staffId": Number(this.state.selectedStaffId),
      "location": this.state.selectedShiftDetails.clientLocation,
      "Date": this.state.selectedShiftDate,
      "startTime": this.state.selectedShiftDetails.startTime,
      "endTime": this.state.selectedShiftDetails.endTime,
      "breaking": this.state.selectedShiftDetails.breaking,
      "notes": this.state.selectedShiftDetails.notes,
      "allowance": Number(this.state.selectedShiftDetails.allowance)
    };

    if (Number(this.state.selectedStaffId) == -1) {

      axios.post(`https://rosterapi.tarisa.com.au/rlsss/shifts/add2?TOCKEN=${this.extractToken().TOCKEN}`, infor)
        .then(res => {

          this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
          this.hideModal();

        }).catch(error => {

        });
    } else {
      axios.post(`https://rosterapi.tarisa.com.au/rlsss/shifts/add?TOCKEN=${this.extractToken().TOCKEN}`, infor)
        .then(res => {

          this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
          this.hideModal();

        }).catch(error => {
          swal("Not pasted!", " ", "warning")

        })
    }


  }

  pasteWeek() {

  }

  calculatePasteWeek() {
    var start = moment(this.state.weekEnd).format('MM-DD-YYYY');
    start = moment(start).add(1, 'days').format('MM-DD-YYYY');
    var end = moment(start).add(6, 'days').format('MM-DD-YYYY')
    var arr = [];
    for (let index = 0; index < 9; index++) {
      var end = moment(start).add(6, 'days').format('MM-DD-YYYY');
      arr.push({ weekStart: start, weekEnd: end });
      start = moment(end).add(1, 'days').format('MM-DD-YYYY');
    }
    this.setState({ getNextWeeks: arr });

  }

  componentDidMount() {
    this.extractToken();
    this.getStaff();
    // this.getShifts();
    this.getClients();
    this.getFinSupport();
    this.getSupportServices();
    this.getClientFinancials();
    this.calculateWeekRange();
  }

  saveAlert() {
    swal("Successifully saved!", " ", "success")
      .then(() => {
        this.hideAddModal();
        this.hideModal();
      })
  }
  weekCopyAlert() {
    swal("Week successifully copied!", " ", "success")
      .then(() => {
        this.hideCopyShiftsModal();
        this.hideModal();
      })
  }
  approveAlert() {
    swal("Successifully approved!", " ", "success")
      .then(() => {
        this.hideAddModal();
        this.hideModal();
      })
  }
  deleteSaveAlert() {
    swal("Successifuly deleted!", " ", "success")
      .then(() => {
        this.hideModal();
      })
  }

  deleteAlert() {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          this.deleteShift();
        }
      })
  }

  getStaff() {

    //FETCHING ALL STAFF FROM API
    var url1 = `https://rosterapi.tarisa.com.au/rlsss/shifts/staff?TOCKEN=${this.extractToken().TOCKEN}`;
    axios.get(url1).then(staff => {

      this.setState({ staffList: staff.data });


    }).catch(error => {

    })
  }

  editRow(props) {

  }


  render() {

    const columns = [{
      Header: () => (
        <div>
          <form>
            <input className="form-control" type="text" placeholder="Search" />
          </form>
        </div>
      ),
      accessor: 'staffName', // String-based value accessors!
      minWidth: 230,
      sortable : false

    }, {
      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Sunday
          <br></br>
          {
            moment(moment(this.state.weekStart).format('MM-DD-YYYY')).add(0, 'days').format('DD-MM-YYYY')
          }
        </button>
      ),
      accessor: 'ending_time',
      minWidth: 180,
      Cell: row => {
        var e = row.original;
        var staffId = e.staffId;
        var array = [];
        var display = [];
        array = e.shifts;
        var index = 0;
        index = array.findIndex(x => x.day === 'Sunday');
        if (index >= 0) {
          array.forEach(element => {
            if (element.day == 'Sunday') {
              display.push(element);
            }
          });

          // return display.map(function(item, i){
          //   return <div> <button 
          //             onClick={() => {

          //               this.setState({ selectedShiftId : item.shiftId});
          //               console.log(this.state.selectedStaffId)
          //             }} key={item.shiftId}
          //             >
          //             {item.startTime}-{item.endTime}
          //           </button>
          //           </div>
          //   },this)
          return display.map(function (item, i) {
            return <div>
              <button className="btn  btn-info btn-block btn-sm mb-1"

                key={i}
              >
                <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}>{item.clientLocation}</span></small>
                <small>
                  {item.clientName}<span className="badge badge-danger ml-2 ">{item.startTime}-{item.endTime}</span>
                </small>
                <br></br>
                <button onClick={() => {
                  this.setState({ selectedShiftId: item.shiftId });
                  this.setState({ selectedShiftDate: item.date });
                  this.setState({ selectedStaffId: e.staffId });
                  this.setState({ selectedStaffName: e.staffName });

                  this.setState({ selectedShiftDetails: { shiftId: item.shiftId, hourlChage: item.hourlChage, startTime: item.startTime, endTime: item.endTime, clientId: item.clientId, clientLocation: item.clientLocation, supportAreaId: item.supportAreaId, supportServiceId: item.supportServiceId, staffId: item.staffId, notes: item.notes, breaking: item.breaking, isApproved: item.isApproved, financialPackageId: item.financialPackageId, isCompleted: item.isCompleted, allowance: item.allowance } });
                  this.showModal()
                }}
                  className="btn  btn-outline-warning btn-sm mb-1 mr-1"
                >
                  <small>Edit</small>
                </button>
                <button onClick={() => {
                  var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                  var today = moment(weekStart).add(0, 'days').format('MM-DD-YYYY')
                  this.setState({ selectedStaffId: e.staffId });
                  this.setState({ selectedStaffName: e.staffName });
                  this.setState({ selectedShiftDate: item.date });
                  this.setState({ selectedStaffId: e.staffId });
                  this.setState({ selectedStaffName: e.staffName });
                  this.showAddModal();
                }}
                  className="btn  btn-outline-warning btn-sm mb-1 mr-1"
                >
                  +
             </button>
                <button onClick={() => {
                  this.setState({ selectedShiftId: item.shiftId });
                  this.setState({ selectedShiftDate: item.date });
                  this.setState({ selectedStaffId: e.staffId });
                  this.setState({ selectedStaffName: e.staffName });

                  this.setState({ selectedShiftDetails: { shiftId: item.shiftId, hourlChage: item.hourlChage, startTime: item.startTime, endTime: item.endTime, clientId: item.clientId, clientLocation: item.clientLocation, supportAreaId: item.supportAreaId, supportServiceId: item.supportServiceId, staffId: item.staffId, notes: item.notes, breaking: item.breaking, isApproved: item.isApproved, financialPackageId: item.financialPackageId, isCompleted: item.isCompleted, allowance: item.allowance } });
                }}
                  className="btn  btn-outline-warning btn-sm mb-1 mr-1"
                >
                  (C)
                 </button>
                <button
                  onClick={() => {

                    this.pasteShift();
                  }}
                  onMouseEnter={
                    () => {
                      this.setState({ selectedShiftDate: item.date });
                      this.setState({ selectedStaffId: e.staffId });
                    }
                  }
                  className="btn  btn-outline-warning btn-sm mb-1 mr-1"
                >
                  (P)
                  </button>
              </button>

            </div>
          }, this)
        } else {
          return (
            <div>
              <button
                className="btn  btn-outline-secondary btn-block"
              >
                <button
                  onClick={() => {
                    var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                    var today = moment(weekStart).add(0, 'days').format('MM-DD-YYYY')
                    this.setState({ selectedStaffId: e.staffId });
                    this.setState({ selectedStaffName: e.staffName });
                    this.setState({ selectedShiftDate: today });
                    this.showAddModal();

                  }}
                  className="btn  btn-outline-warning btn-full btn-sm mr-1"
                >
                  +
              </button>
                <button
                  onClick={() => {

                    this.pasteShift();
                  }}
                  onMouseEnter={
                    () => {
                      var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                      var today = moment(weekStart).add(0, 'days').format('MM-DD-YYYY')
                      this.setState({ selectedStaffId: e.staffId });
                      this.setState({ selectedStaffName: e.staffName });
                      this.setState({ selectedShiftDate: today });


                    }
                  }
                  className="btn  btn-outline-warning btn-full btn-sm"
                >
                  Paste
              </button>

              </button>

            </div>
          )
        }
      }
    }, {
      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Monday
          <br></br>
          {
            moment(moment(this.state.weekStart).format('MM-DD-YYYY')).add(1, 'days').format('DD-MM-YYYY')
          }
        </button>
      ),
      accessor: 'ending_time',
      minWidth: 180,
      Cell: row => {
        var e = row.original;
        var staffId = e.staffId;
        var array = [];
        var display = [];
        array = e.shifts;
        var index = 0;
        index = array.findIndex(x => x.day === 'Monday');
        if (index >= 0) {
          array.forEach(element => {
            if (element.day == 'Monday') {
              display.push(element);
            }
          });
          return display.map(function (item, i) {
            return <div>
              <button className="btn  btn-info btn-block btn-sm mb-1"

                key={i}
              >
                <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}>{item.clientLocation}</span></small>
                <small>
                  {item.clientName}<span className="badge badge-danger ml-2">{item.startTime}-{item.endTime}</span>
                </small>
                <br></br>
                <button onClick={() => {
                  this.setState({ selectedShiftId: item.shiftId });
                  this.setState({ selectedShiftDate: item.date });
                  this.setState({ selectedStaffId: e.staffId });
                  this.setState({ selectedStaffName: e.staffName });

                  this.setState({ selectedShiftDetails: { shiftId: item.shiftId, hourlChage: item.hourlChage, startTime: item.startTime, endTime: item.endTime, clientId: item.clientId, clientLocation: item.clientLocation, supportAreaId: item.supportAreaId, supportServiceId: item.supportServiceId, staffId: item.staffId, notes: item.notes, breaking: item.breaking, isApproved: item.isApproved, financialPackageId: item.financialPackageId, isCompleted: item.isCompleted, allowance: item.allowance } });
                  this.showModal()
                }}
                  className="btn  btn-outline-warning btn-sm mb-1 mr-1"
                >
                  <small>Edit</small>
                </button>
                <button onClick={() => {
                  var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                  var today = moment(weekStart).add(0, 'days').format('MM-DD-YYYY')
                  this.setState({ selectedStaffId: e.staffId });
                  this.setState({ selectedStaffName: e.staffName });
                  this.setState({ selectedShiftDate: item.date });
                  this.setState({ selectedStaffId: e.staffId });
                  this.setState({ selectedStaffName: e.staffName });
                  this.showAddModal();
                }}
                  className="btn  btn-outline-warning btn-sm mb-1 mr-1"
                >
                  +
           </button>
                <button onClick={() => {
                  this.setState({ selectedShiftId: item.shiftId });
                  this.setState({ selectedShiftDate: item.date });
                  this.setState({ selectedStaffId: e.staffId });
                  this.setState({ selectedStaffName: e.staffName });

                  this.setState({ selectedShiftDetails: { shiftId: item.shiftId, hourlChage: item.hourlChage, startTime: item.startTime, endTime: item.endTime, clientId: item.clientId, clientLocation: item.clientLocation, supportAreaId: item.supportAreaId, supportServiceId: item.supportServiceId, staffId: item.staffId, notes: item.notes, breaking: item.breaking, isApproved: item.isApproved, financialPackageId: item.financialPackageId, isCompleted: item.isCompleted, allowance: item.allowance } });
                }}
                  className="btn  btn-outline-warning btn-sm mb-1 mr-1"
                >
                  (C)
               </button>
                <button
                  onClick={() => {

                    this.pasteShift();
                  }}
                  onMouseEnter={
                    () => {
                      this.setState({ selectedShiftDate: item.date });
                      this.setState({ selectedStaffId: e.staffId });
                    }
                  }
                  className="btn  btn-outline-warning btn-sm mb-1 mr-1"
                >
                  (P)
                </button>
              </button>

            </div>
          }, this)
        } else {
          return (
            <div>
              <button
                className="btn  btn-outline-secondary btn-block"
              >
                <button
                  onClick={() => {
                    var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                    var today = moment(weekStart).add(1, 'days').format('MM-DD-YYYY')
                    this.setState({ selectedStaffId: e.staffId });
                    this.setState({ selectedStaffName: e.staffName });
                    this.setState({ selectedShiftDate: today });
                    this.showAddModal();

                  }}
                  className="btn  btn-outline-warning btn-full btn-sm mr-1"
                >
                  +
              </button>
                <button
                  onClick={() => {

                    this.pasteShift();
                  }}
                  onMouseEnter={
                    () => {
                      var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                      var today = moment(weekStart).add(1, 'days').format('MM-DD-YYYY')
                      this.setState({ selectedStaffId: e.staffId });
                      this.setState({ selectedStaffName: e.staffName });
                      this.setState({ selectedShiftDate: today });


                    }
                  }
                  className="btn  btn-outline-warning btn-full btn-sm"
                >
                  Paste
              </button>

              </button>

            </div>
          )
        }
      }
    }, {
      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Tuesday
          <br></br>
          {
            moment(moment(this.state.weekStart).format('MM-DD-YYYY')).add(2, 'days').format('DD-MM-YYYY')
          }
        </button>
      ),
      minWidth: 180,
      Cell: row => {
        var e = row.original;
        var array = [];
        var display = [];
        array = e.shifts;
        var index = 0;
        index = array.findIndex(x => x.day === 'Tuesday');
        if (index >= 0) {
          array.forEach(element => {
            if (element.day == 'Tuesday') {
              display.push(element);
            }
          });
          return display.map(function (item, i) {
            return <div>
              <button className="btn  btn-info btn-block btn-sm mb-1"

                key={i}
              >
                <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}>{item.clientLocation}</span></small>
                <small>
                  {item.clientName}<span className="badge badge-danger ml-2">{item.startTime}-{item.endTime}</span>
                </small>
                <br></br>
                <button onClick={() => {
                  this.setState({ selectedShiftId: item.shiftId });
                  this.setState({ selectedShiftDate: item.date });
                  this.setState({ selectedStaffId: e.staffId });
                  this.setState({ selectedStaffName: e.staffName });

                  this.setState({ selectedShiftDetails: { shiftId: item.shiftId, hourlChage: item.hourlChage, startTime: item.startTime, endTime: item.endTime, clientId: item.clientId, clientLocation: item.clientLocation, supportAreaId: item.supportAreaId, supportServiceId: item.supportServiceId, staffId: item.staffId, notes: item.notes, breaking: item.breaking, isApproved: item.isApproved, financialPackageId: item.financialPackageId, isCompleted: item.isCompleted, allowance: item.allowance } });
                  this.showModal()
                }}
                  className="btn  btn-outline-warning btn-sm mb-1 mr-1"
                >
                  <small>Edit</small>
                </button>
                <button onClick={() => {
                  var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                  var today = moment(weekStart).add(0, 'days').format('MM-DD-YYYY')
                  this.setState({ selectedStaffId: e.staffId });
                  this.setState({ selectedStaffName: e.staffName });
                  this.setState({ selectedShiftDate: item.date });
                  this.setState({ selectedStaffId: e.staffId });
                  this.setState({ selectedStaffName: e.staffName });
                  this.showAddModal();
                }}
                  className="btn  btn-outline-warning btn-sm mb-1 mr-1"
                >
                  +
             </button>
                <button onClick={() => {
                  this.setState({ selectedShiftId: item.shiftId });
                  this.setState({ selectedShiftDate: item.date });
                  this.setState({ selectedStaffId: e.staffId });
                  this.setState({ selectedStaffName: e.staffName });

                  this.setState({ selectedShiftDetails: { shiftId: item.shiftId, hourlChage: item.hourlChage, startTime: item.startTime, endTime: item.endTime, clientId: item.clientId, clientLocation: item.clientLocation, supportAreaId: item.supportAreaId, supportServiceId: item.supportServiceId, staffId: item.staffId, notes: item.notes, breaking: item.breaking, isApproved: item.isApproved, financialPackageId: item.financialPackageId, isCompleted: item.isCompleted, allowance: item.allowance } });
                }}
                  className="btn  btn-outline-warning btn-sm mb-1 mr-1"
                >
                  (C)
                 </button>
                <button
                  onClick={() => {

                    this.pasteShift();
                  }}
                  onMouseEnter={
                    () => {
                      this.setState({ selectedShiftDate: item.date });
                      this.setState({ selectedStaffId: e.staffId });
                    }
                  }
                  className="btn  btn-outline-warning btn-sm mb-1 mr-1"
                >
                  (P)
                  </button>
              </button>

            </div>
          }, this)
        } else {
          return (
            <div>
              <button
                className="btn  btn-outline-secondary btn-block"
              >
                <button
                  onClick={() => {
                    var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                    var today = moment(weekStart).add(2, 'days').format('MM-DD-YYYY')
                    this.setState({ selectedStaffId: e.staffId });
                    this.setState({ selectedStaffName: e.staffName });
                    this.setState({ selectedShiftDate: today });
                    this.showAddModal();

                  }}
                  className="btn  btn-outline-warning btn-full btn-sm mr-1"
                >
                  +
              </button>
                <button
                  onClick={() => {

                    this.pasteShift();
                  }}
                  onMouseEnter={
                    () => {
                      var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                      var today = moment(weekStart).add(2, 'days').format('MM-DD-YYYY')
                      this.setState({ selectedStaffId: e.staffId });
                      this.setState({ selectedStaffName: e.staffName });
                      this.setState({ selectedShiftDate: today });


                    }
                  }
                  className="btn  btn-outline-warning btn-full btn-sm"
                >
                  Paste
              </button>

              </button>

            </div>
          )
        }
      }
    }, {
      id: 'starting_date', // Required because our accessor is not a string
      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Wednesday
          <br></br>
          {
            moment(moment(this.state.weekStart).format('MM-DD-YYYY')).add(3, 'days').format('DD-MM-YYYY')
          }
        </button>
      ),
      minWidth: 180,
      Cell: row => {
        var e = row.original;
        var array = [];
        var display = [];
        array = e.shifts;
        var index = 0;
        index = array.findIndex(x => x.day === 'Wednesday');
        if (index >= 0) {
          array.forEach(element => {
            if (element.day == 'Wednesday') {
              display.push(element);
            }
          });
          return display.map(function (item, i) {
            return <div>
              <button className="btn  btn-info btn-block btn-sm mb-1"

                key={i}
              >
                <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}>{item.clientLocation}</span></small>
                <small>
                  {item.clientName}<span className="badge badge-danger ml-2">{item.startTime}-{item.endTime}</span>
                </small>
                <br></br>
                <button onClick={() => {
                  this.setState({ selectedShiftId: item.shiftId });
                  this.setState({ selectedShiftDate: item.date });
                  this.setState({ selectedStaffId: e.staffId });
                  this.setState({ selectedStaffName: e.staffName });

                  this.setState({ selectedShiftDetails: { shiftId: item.shiftId, hourlChage: item.hourlChage, startTime: item.startTime, endTime: item.endTime, clientId: item.clientId, clientLocation: item.clientLocation, supportAreaId: item.supportAreaId, supportServiceId: item.supportServiceId, staffId: item.staffId, notes: item.notes, breaking: item.breaking, isApproved: item.isApproved, financialPackageId: item.financialPackageId, isCompleted: item.isCompleted, allowance: item.allowance } });
                  this.showModal()
                }}
                  className="btn  btn-outline-warning btn-sm mb-1 mr-1"
                >
                  <small>Edit</small>
                </button>
                <button onClick={() => {
                  var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                  var today = moment(weekStart).add(0, 'days').format('MM-DD-YYYY')
                  this.setState({ selectedStaffId: e.staffId });
                  this.setState({ selectedStaffName: e.staffName });
                  this.setState({ selectedShiftDate: item.date });
                  this.setState({ selectedStaffId: e.staffId });
                  this.setState({ selectedStaffName: e.staffName });
                  this.showAddModal();
                }}
                  className="btn  btn-outline-warning btn-sm mb-1 mr-1"
                >
                  +
             </button>
                <button onClick={() => {
                  this.setState({ selectedShiftId: item.shiftId });
                  this.setState({ selectedShiftDate: item.date });
                  this.setState({ selectedStaffId: e.staffId });
                  this.setState({ selectedStaffName: e.staffName });

                  this.setState({ selectedShiftDetails: { shiftId: item.shiftId, hourlChage: item.hourlChage, startTime: item.startTime, endTime: item.endTime, clientId: item.clientId, clientLocation: item.clientLocation, supportAreaId: item.supportAreaId, supportServiceId: item.supportServiceId, staffId: item.staffId, notes: item.notes, breaking: item.breaking, isApproved: item.isApproved, financialPackageId: item.financialPackageId, isCompleted: item.isCompleted, allowance: item.allowance } });
                }}
                  className="btn  btn-outline-warning btn-sm mb-1 mr-1"
                >
                  (C)
                 </button>
                <button
                  onClick={() => {

                    this.pasteShift();
                  }}
                  onMouseEnter={
                    () => {
                      this.setState({ selectedShiftDate: item.date });
                      this.setState({ selectedStaffId: e.staffId });
                    }
                  }
                  className="btn  btn-outline-warning btn-sm mb-1 mr-1"
                >
                  (P)
                  </button>
              </button>

            </div>
          }, this)
        } else {
          return (
            <div>
              <button
                className="btn  btn-outline-secondary btn-block"
              >
                <button
                  onClick={() => {
                    var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                    var today = moment(weekStart).add(3, 'days').format('MM-DD-YYYY')
                    this.setState({ selectedStaffId: e.staffId });
                    this.setState({ selectedStaffName: e.staffName });
                    this.setState({ selectedShiftDate: today });
                    this.showAddModal();

                  }}
                  className="btn  btn-outline-warning btn-full btn-sm mr-1"
                >
                  +
              </button>
                <button
                  onClick={() => {

                    this.pasteShift();
                  }}
                  onMouseEnter={
                    () => {
                      var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                      var today = moment(weekStart).add(3, 'days').format('MM-DD-YYYY')
                      this.setState({ selectedStaffId: e.staffId });
                      this.setState({ selectedStaffName: e.staffName });
                      this.setState({ selectedShiftDate: today });


                    }
                  }
                  className="btn  btn-outline-warning btn-full btn-sm"
                >
                  Paste
              </button>

              </button>

            </div>
          )
        }
      }
    }, {
      id: 'ending_date', // Required because our accessor is not a string
      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Thursday
          <br></br>
          {
            moment(moment(this.state.weekStart).format('MM-DD-YYYY')).add(4, 'days').format('DD-MM-YYYY')
          }
        </button>
      ),
      minWidth: 180,
      Cell: row => {
        var e = row.original;
        var array = [];
        var display = [];
        array = e.shifts;
        var index = 0;
        index = array.findIndex(x => x.day === 'Thursday');
        if (index >= 0) {
          array.forEach(element => {
            if (element.day == 'Thursday') {
              display.push(element);
            }
          });
          return display.map(function (item, i) {
            return <div>
              <button className="btn  btn-info btn-block btn-sm mb-1"

                key={i}
              >
                <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}>{item.clientLocation}</span></small>
                <small>
                  {item.clientName}<span className="badge badge-danger ml-2">{item.startTime}-{item.endTime}</span>
                </small>
                <br></br>
                <button onClick={() => {
                  this.setState({ selectedShiftId: item.shiftId });
                  this.setState({ selectedShiftDate: item.date });
                  this.setState({ selectedStaffId: e.staffId });
                  this.setState({ selectedStaffName: e.staffName });

                  this.setState({ selectedShiftDetails: { shiftId: item.shiftId, hourlChage: item.hourlChage, startTime: item.startTime, endTime: item.endTime, clientId: item.clientId, clientLocation: item.clientLocation, supportAreaId: item.supportAreaId, supportServiceId: item.supportServiceId, staffId: item.staffId, notes: item.notes, breaking: item.breaking, isApproved: item.isApproved, financialPackageId: item.financialPackageId, isCompleted: item.isCompleted, allowance: item.allowance } });
                  this.showModal()
                }}
                  className="btn  btn-outline-warning btn-sm mb-1 mr-1"
                >
                  <small className="xs">Edit</small>
                </button>
                <button onClick={() => {
                  var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                  var today = moment(weekStart).add(0, 'days').format('MM-DD-YYYY')
                  this.setState({ selectedStaffId: e.staffId });
                  this.setState({ selectedStaffName: e.staffName });
                  this.setState({ selectedShiftDate: item.date });
                  this.setState({ selectedStaffId: e.staffId });
                  this.setState({ selectedStaffName: e.staffName });
                  this.showAddModal();
                }}
                  className="btn  btn-outline-warning btn-sm mb-1 mr-1"
                >
                  +
             </button>
                <button onClick={() => {
                  this.setState({ selectedShiftId: item.shiftId });
                  this.setState({ selectedShiftDate: item.date });
                  this.setState({ selectedStaffId: e.staffId });
                  this.setState({ selectedStaffName: e.staffName });

                  this.setState({ selectedShiftDetails: { shiftId: item.shiftId, hourlChage: item.hourlChage, startTime: item.startTime, endTime: item.endTime, clientId: item.clientId, clientLocation: item.clientLocation, supportAreaId: item.supportAreaId, supportServiceId: item.supportServiceId, staffId: item.staffId, notes: item.notes, breaking: item.breaking, isApproved: item.isApproved, financialPackageId: item.financialPackageId, isCompleted: item.isCompleted, allowance: item.allowance } });
                }}
                  className="btn  btn-outline-warning btn-sm mb-1 mr-1"
                >
                  (C)
                 </button>
                <button
                  onClick={() => {

                    this.pasteShift();
                  }}
                  onMouseEnter={
                    () => {
                      this.setState({ selectedShiftDate: item.date });
                      this.setState({ selectedStaffId: e.staffId });
                    }
                  }
                  className="btn  btn-outline-warning btn-sm mb-1 mr-1"
                >
                  (P)
                  </button>
              </button>

            </div>
          }, this)
        } else {
          return (
            <div>
              <button
                className="btn  btn-outline-secondary btn-block"
              >
                <button
                  onClick={() => {
                    var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                    var today = moment(weekStart).add(4, 'days').format('MM-DD-YYYY')
                    this.setState({ selectedStaffId: e.staffId });
                    this.setState({ selectedStaffName: e.staffName });
                    this.setState({ selectedShiftDate: today });
                    this.showAddModal();

                  }}
                  className="btn  btn-outline-warning btn-full btn-sm mr-1"
                >
                  +
              </button>
                <button
                  onClick={() => {

                    this.pasteShift();
                  }}
                  onMouseEnter={
                    () => {
                      var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                      var today = moment(weekStart).add(4, 'days').format('MM-DD-YYYY')
                      this.setState({ selectedStaffId: e.staffId });
                      this.setState({ selectedStaffName: e.staffName });
                      this.setState({ selectedShiftDate: today });


                    }
                  }
                  className="btn  btn-outline-warning btn-full btn-sm"
                >
                  Paste
              </button>

              </button>

            </div>
          )
        }
      }
    }, {
      id: 'starting_time', // Required because our accessor is not a string
      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Friday
          <br></br>
          {
            moment(moment(this.state.weekStart).format('MM-DD-YYYY')).add(5, 'days').format('DD-MM-YYYY')
          }
        </button>
      ),
      minWidth: 180,
      Cell: row => {
        var e = row.original;
        var array = [];
        var display = [];
        array = e.shifts;
        var index = 0;
        index = array.findIndex(x => x.day === 'Friday');
        if (index >= 0) {
          array.forEach(element => {
            if (element.day == 'Friday') {
              display.push(element);
            }
          });
          return display.map(function (item, i) {
            return <div>
              <button className="btn  btn-info btn-block btn-sm mb-1"

                key={i}
              >
                <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}>{item.clientLocation}</span></small>
                <small>
                  {item.clientName}<span className="badge badge-danger ml-2">{item.startTime}-{item.endTime}</span>
                </small>
                <br></br>
                <button onClick={() => {
                  this.setState({ selectedShiftId: item.shiftId });
                  this.setState({ selectedShiftDate: item.date });
                  this.setState({ selectedStaffId: e.staffId });
                  this.setState({ selectedStaffName: e.staffName });

                  this.setState({ selectedShiftDetails: { shiftId: item.shiftId, hourlChage: item.hourlChage, startTime: item.startTime, endTime: item.endTime, clientId: item.clientId, clientLocation: item.clientLocation, supportAreaId: item.supportAreaId, supportServiceId: item.supportServiceId, staffId: item.staffId, notes: item.notes, breaking: item.breaking, isApproved: item.isApproved, financialPackageId: item.financialPackageId, isCompleted: item.isCompleted, allowance: item.allowance } });
                  this.showModal()
                }}
                  className="btn  btn-outline-warning btn-sm mb-1 mr-1"
                >
                  <small>Edit</small>
                </button>
                <button onClick={() => {
                  var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                  var today = moment(weekStart).add(0, 'days').format('MM-DD-YYYY')
                  this.setState({ selectedStaffId: e.staffId });
                  this.setState({ selectedStaffName: e.staffName });
                  this.setState({ selectedShiftDate: item.date });
                  this.setState({ selectedStaffId: e.staffId });
                  this.setState({ selectedStaffName: e.staffName });
                  this.showAddModal();
                }}
                  className="btn  btn-outline-warning btn-sm mb-1 mr-1"
                >
                  +
             </button>
                <button onClick={() => {
                  this.setState({ selectedShiftId: item.shiftId });
                  this.setState({ selectedShiftDate: item.date });
                  this.setState({ selectedStaffId: e.staffId });
                  this.setState({ selectedStaffName: e.staffName });

                  this.setState({ selectedShiftDetails: { shiftId: item.shiftId, hourlChage: item.hourlChage, startTime: item.startTime, endTime: item.endTime, clientId: item.clientId, clientLocation: item.clientLocation, supportAreaId: item.supportAreaId, supportServiceId: item.supportServiceId, staffId: item.staffId, notes: item.notes, breaking: item.breaking, isApproved: item.isApproved, financialPackageId: item.financialPackageId, isCompleted: item.isCompleted, allowance: item.allowance } });
                }}
                  className="btn  btn-outline-warning btn-sm mb-1 mr-1"
                >
                  (C)
                 </button>
                <button
                  onClick={() => {

                    this.pasteShift();
                  }}
                  onMouseEnter={
                    () => {
                      this.setState({ selectedShiftDate: item.date });
                      this.setState({ selectedStaffId: e.staffId });
                    }
                  }
                  className="btn  btn-outline-warning btn-sm mb-1 mr-1"
                >
                  (P)
                  </button>
              </button>

            </div>
          }, this)
        } else {
          return (
            <div>
              <button
                className="btn  btn-outline-secondary btn-block"
              >
                <button
                  onClick={() => {
                    var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                    var today = moment(weekStart).add(5, 'days').format('MM-DD-YYYY')
                    this.setState({ selectedStaffId: e.staffId });
                    this.setState({ selectedStaffName: e.staffName });
                    this.setState({ selectedShiftDate: today });
                    this.showAddModal();

                  }}
                  className="btn  btn-outline-warning btn-full btn-sm mr-1"
                >
                  +
              </button>
                <button
                  onClick={() => {

                    this.pasteShift();
                  }}
                  onMouseEnter={
                    () => {
                      var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                      var today = moment(weekStart).add(5, 'days').format('MM-DD-YYYY')
                      this.setState({ selectedStaffId: e.staffId });
                      this.setState({ selectedStaffName: e.staffName });
                      this.setState({ selectedShiftDate: today });


                    }
                  }
                  className="btn  btn-outline-warning btn-full btn-sm"
                >
                  Paste
              </button>

              </button>

            </div>
          )
        }
      }
    }, {
      id: 'ending_time', // Required because our accessor is not a string
      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Saturday
          <br></br>
          {
            moment(moment(this.state.weekStart).format('MM-DD-YYYY')).add(6, 'days').format('DD-MM-YYYY')
          }
        </button>
      ),
      minWidth: 180,
      Cell: row => {
        var e = row.original;
        var array = [];
        var display = [];
        array = e.shifts;
        var index = 0;
        index = array.findIndex(x => x.day === 'Saturday');
        if (index >= 0) {
          array.forEach(element => {
            if (element.day == 'Saturday') {
              display.push(element);
            }
          });
          return display.map(function (item, i) {
            return <div>
              <button className="btn  btn-info btn-block btn-sm mb-1"

                key={i}
              >
                <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}>{item.clientLocation}</span></small>
                <small>
                  {item.clientName}<span className="badge badge-danger ml-2">{item.startTime}-{item.endTime}</span>
                </small>
                <br></br>
                <button onClick={() => {
                  this.setState({ selectedShiftId: item.shiftId });
                  this.setState({ selectedShiftDate: item.date });
                  this.setState({ selectedStaffId: e.staffId });
                  this.setState({ selectedStaffName: e.staffName });

                  this.setState({ selectedShiftDetails: { shiftId: item.shiftId, hourlChage: item.hourlChage, startTime: item.startTime, endTime: item.endTime, clientId: item.clientId, clientLocation: item.clientLocation, supportAreaId: item.supportAreaId, supportServiceId: item.supportServiceId, staffId: item.staffId, notes: item.notes, breaking: item.breaking, isApproved: item.isApproved, financialPackageId: item.financialPackageId, isCompleted: item.isCompleted, allowance: item.allowance } });
                  this.showModal()
                }}
                  className="btn  btn-outline-warning btn-sm mb-1 mr-1"
                >
                  <small>Edit</small>
                </button>
                <button onClick={() => {
                  var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                  var today = moment(weekStart).add(0, 'days').format('MM-DD-YYYY')
                  this.setState({ selectedStaffId: e.staffId });
                  this.setState({ selectedStaffName: e.staffName });
                  this.setState({ selectedShiftDate: item.date });
                  this.setState({ selectedStaffId: e.staffId });
                  this.setState({ selectedStaffName: e.staffName });
                  this.showAddModal();
                }}
                  className="btn  btn-outline-warning btn-sm mb-1 mr-1"
                >
                  +
             </button>
                <button onClick={() => {
                  this.setState({ selectedShiftId: item.shiftId });
                  this.setState({ selectedShiftDate: item.date });
                  this.setState({ selectedStaffId: e.staffId });
                  this.setState({ selectedStaffName: e.staffName });

                  this.setState({ selectedShiftDetails: { shiftId: item.shiftId, hourlChage: item.hourlChage, startTime: item.startTime, endTime: item.endTime, clientId: item.clientId, clientLocation: item.clientLocation, supportAreaId: item.supportAreaId, supportServiceId: item.supportServiceId, staffId: item.staffId, notes: item.notes, breaking: item.breaking, isApproved: item.isApproved, financialPackageId: item.financialPackageId, isCompleted: item.isCompleted, allowance: item.allowance } });
                }}
                  className="btn  btn-outline-warning btn-sm mb-1 mr-1"
                >
                  (C)
                 </button>
                <button
                  onClick={() => {

                    this.pasteShift();
                  }}
                  onMouseEnter={
                    () => {
                      this.setState({ selectedShiftDate: item.date });
                      this.setState({ selectedStaffId: e.staffId });
                    }
                  }
                  className="btn  btn-outline-warning btn-sm mb-1 mr-1"
                >
                  (P)
                  </button>
              </button>

            </div>
          }, this)
        } else {
          return (
            <div>
              <button
                className="btn  btn-outline-secondary btn-block"
              >
                <button
                  onClick={() => {
                    var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                    var today = moment(weekStart).add(6, 'days').format('MM-DD-YYYY')
                    this.setState({ selectedStaffId: e.staffId });
                    this.setState({ selectedStaffName: e.staffName });
                    this.setState({ selectedShiftDate: today });
                    this.showAddModal();

                  }}
                  className="btn  btn-outline-warning btn-full btn-sm mr-1"
                >
                  +
              </button>
                <button
                  onClick={() => {

                    this.pasteShift();
                  }}
                  onMouseEnter={
                    () => {
                      var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                      var today = moment(weekStart).add(6, 'days').format('MM-DD-YYYY')
                      this.setState({ selectedStaffId: e.staffId });
                      this.setState({ selectedStaffName: e.staffName });
                      this.setState({ selectedShiftDate: today });


                    }
                  }
                  className="btn  btn-outline-warning btn-full btn-sm"
                >
                  Paste
              </button>

              </button>

            </div>
          )
        }
      }
    }]

    return (
      <div >
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 ml-2 mr-2">
          <h1 className="h2">Schedule</h1>
          <div className="btn-toolbar mb-2 mb-md-0">
            {/* <button
              
              type="button"
              className="btn btn-sm btn-outline-secondary mr-2"
            >
            &#8635; Refresh
          </button> */}
            <button
              type="button"
              className="btn btn-sm btn-outline-success mr-2"
            >

              {this.state.totalShifts.totalAdminConfirmed} Admin confirmed
          </button>
            <button
              type="button"
              className="btn btn-sm btn-outline-success mr-2"
            >

              {this.state.totalShifts.totalCompleted} Completed

          </button>
            <button
              type="button"
              className="btn btn-sm btn-outline-success mr-2"
            >

              {this.state.totalShifts.totalApproved} Approved

          </button>
            <button
              type="button"
              className="btn btn-sm btn-outline-success mr-2"
            >

              {this.state.totalShifts.totalShifts} Shifts

          </button>

            <div className="btn-group mr-2">
              <button onClick={this.getPreviousWeek} type="button" className="btn btn-sm btn-outline-secondary">&#10094;</button>
              <button type="button" className="btn btn-sm btn-outline-secondary">
                <span data-feather="calendar"></span>{moment(this.state.weekStart).format('DD-MM-YYYY')} - {moment(this.state.weekEnd).format('DD-MM-YYYY')}</button>
              <button onClick={this.getNextWeek} type="button" className="btn btn-sm btn-outline-secondary">&#10095;</button>
            </div>
            <button
              type="button"
              className="btn btn-sm btn-outline-secondary mr-2"
              onClick={() => {
                this.showCopyShiftsModal();
                this.calculatePasteWeek();
              }}
            >
              Copy week
          </button>

            <div className="btn-group mr-2">
              <a href={`https://rosterapi.tarisa.com.au/rlsss/shifts/exportExcel?start=${this.state.weekStart}&end=${this.state.weekEnd}&TOCKEN=${this.extractToken().TOCKEN}`}
                type="button"
                className="btn btn-sm btn-outline-secondary"
              >
                &#8645; Export
                </a>
            </div>
            {/* <div className="dropdown">
              <button type="button" className="btn btn-sm btn-outline-secondary dropdown-toggle mr-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Week view
          </button>
              <div className="dropdown-menu" aria-labelledby="dropdown4">
                <a className="dropdown-item" href="#">Day view</a>
                <a className="dropdown-item" href="#">Month view</a>
              </div>
            </div> */}
            {/* <button type="button" className="btn btn-sm btn-outline-secondary mr-2">
              <span data-feather="calendar"></span>
            Options
          </button> */}

          </div>
        </div>
        <div

        >
          <ReactTable
            data={this.state.DatabaseData}
            columns={columns}
            defaultPageSize={10}
          />
        </div>

        {/* EDIT SHIFT MODAL */}
        <Modal show={this.state.ShowModal} onHide={true} size="lg" >
          <Modal.Header>
            <Modal.Title><h5 className="modal-title mr-5" id="exampleModalLabel">Edit shift on {this.state.selectedShiftDate}</h5></Modal.Title>
            {this.state.selectedShiftDetails.isApproved ? <button type="button" className="btn btn-outline-success btn-sm mr-3">Approved</button> : <button type="button" className="btn btn-outline-secondary btn-sm mr-3">Not approved</button>}
            {this.state.selectedShiftDetails.isApproved && this.state.selectedShiftDetails.isCompleted ? <button type="button" className="btn btn-outline-warning btn-sm">Shift completed</button> : <button type="button" className="btn btn-outline-secondary btn-sm">uncompleted shift</button>}

            <button type="button" className="close" onClick={this.hideModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="row">

              <div className="col-md-12">
                <form>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label htmlFor="inputEmail4">Who is working?</label>
                      <input className="form-control" name="staffId" value={this.state.selectedStaffName}></input>
                    </div>

                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label htmlFor="inputAddress">Location</label>
                      <input defaultValue={this.state.selectedShiftDetails.clientLocation} type="text" className="form-control" name="address" onChange={event => this.setTitle(event.target)} placeholder="Location" />
                    </div>

                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-4">
                      <label htmlFor="inputCity">Start time</label>
                      <input defaultValue={this.state.selectedShiftDetails.startTime} type="time" className="form-control" onChange={event => this.setTitle(event.target)} name="startTime" />
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="inputCity">End time</label>
                      <input defaultValue={this.state.selectedShiftDetails.endTime} type="time" className="form-control" onChange={event => this.setTitle(event.target)} name="endTime" />
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="inputCity">Break(min)</label>
                      <input defaultValue={this.state.selectedShiftDetails.breaking} type="number" className="form-control" onChange={event => this.setTitle(event.target)} name="breakMin" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleFormControlTextarea1">Notes</label>
                    <textarea defaultValue={this.state.selectedShiftDetails.notes} className="form-control" onChange={event => this.setTitle(event.target)} name="notes" rows="3"></textarea>
                  </div>
                </form>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {this.state.selectedShiftDetails.isApproved ? '' : <button onClick={this.handleApproveShift} type="button" className="btn btn-success">Approve shift</button>}
            <button type="button" className="btn btn-danger" onClick={this.deleteAlert}>Delete shift</button>
            {this.state.selectedShiftDetails.isCompleted ? ' ' : <button className="btn btn-info" onClick={this.handleEditShift} >Save changes</button>}
          </Modal.Footer>
        </Modal>

        {/* ADD SHIFT MODAL */}
        <Modal show={this.state.ShowAddModal} onHide={true} size="lg" >
          <form onSubmit={this.handleSubmit}>
            <Modal.Header>
              <Modal.Title><h5 className="modal-title" >Add shift on {this.state.selectedShiftDate}</h5></Modal.Title>
              <button type="button" className="close" onClick={this.hideAddModal}>
                <span aria-hidden="true">&times;</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className="row">

                <div className="col-md-12">

                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label htmlFor="inputEmail4">Who is working?</label>
                      <input required className="form-control" name="staffId" value={this.state.selectedStaffName}></input>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label htmlFor="inputAddress">Location</label>
                      <input required type="text" className="form-control" name="address" onChange={event => this.setTitle(event.target)} placeholder="Location" />

                    </div>

                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-4">
                      <label htmlFor="inputCity">Start time</label>
                      <input required type="time" className="form-control" onChange={event => this.setTitle(event.target)} name="startTime" />

                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="inputCity">End time</label>
                      <input required type="time" className="form-control" onChange={event => this.setTitle(event.target)} name="endTime" />

                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="inputCity">Break(min)</label>
                      <input type="number" className="form-control" onChange={event => this.setTitle(event.target)} name="breakMin" />

                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleFormControlTextarea1">Notes</label>
                    <textarea  className="form-control" onChange={event => this.setTitle(event.target)} name="notes" rows="3"></textarea>

                  </div>

                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-info" type="Submit">Save shift</button>
            </Modal.Footer>
          </form>
        </Modal>

        {/* OPEN SHIFTS MODAL */}
        <Modal show={this.state.ShowOpenShiftsModal} onHide={true} size="lg" >
          <Modal.Header>
            <Modal.Title><h5 className="modal-title" >Edit shift on {this.state.selectedShiftDate}</h5></Modal.Title>
            <button type="button" className="close" onClick={this.hideOpenShiftsModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-3">
                <div className="media mb-3">
                  <span className="align-self-center mr-3" ></span>
                  <div className="media-body">
                    <h6 className="mt-0 ">0</h6>
                    <p className="mb-0">shift cost</p>
                  </div>
                </div>
                <div className="media">
                  <span className="align-self-center mr-3" ></span>
                  <div className="media-body">
                    <h6 className="mt-0 ">7.50</h6>
                    <p className="mb-0">Shift length</p>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <form>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="inputEmail4">Who is working?</label>
                      <input disabled="true" className="form-control" name="staffId" value={this.state.selectedStaffName}></input>
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Working on who?</label>
                      <select onChange={event => this.setTitle(event.target)} name="clientName" className="form-control">

                        <option>dickon kudzai</option>
                        <option>englon kavhuru</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-4">
                      <label htmlFor="inputPassword4">Financial Package</label>
                      <select name="clientName" className="form-control">
                        <option>dickon kudzai</option>
                        <option>englon kavhuru</option>
                      </select>
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="inputPassword4">Support Area</label>
                      <select name="clientName" className="form-control">
                        <option>dickon kudzai</option>
                        <option>englon kavhuru</option>
                      </select>
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="inputPassword4">Support Service Area</label>
                      <select name="clientName" className="form-control">
                        <option>dickon kudzai</option>
                        <option>englon kavhuru</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="inputAddress">Location</label>
                      <input value={this.state.selectedShiftId} type="text" className="form-control" name="address" onChange={event => this.setTitle(event.target)} placeholder="Location" />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-4">
                      <label htmlFor="inputCity">Start time</label>
                      <input type="time" className="form-control" onChange={event => this.setTitle(event.target)} name="startTime" />
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="inputCity">End time</label>
                      <input type="time" className="form-control" onChange={event => this.setTitle(event.target)} name="endTime" />
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="inputCity">Break(min)</label>
                      <input type="number" className="form-control" onChange={event => this.setTitle(event.target)} name="breakMin" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleFormControlTextarea1">Notes</label>
                    <textarea className="form-control" onChange={event => this.setTitle(event.target)} name="notes" rows="3"></textarea>
                  </div>
                </form>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-info" onClick={this.handleSubmit}>Save shift</button>
          </Modal.Footer>
        </Modal>

        {/* COPY SHIFTS MODAL */}
        <Modal show={this.state.ShowCopyShiftsModal} onHide={true}  >
          <Modal.Header>
            <Modal.Title><h5 className="modal-title" >Copy shifts</h5></Modal.Title>
            <button type="button" className="close" onClick={this.hideCopyShiftsModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="row">

              <div className="col-md-12" style={{ height: "250px", overflowY: "scroll" }}>
                <form>
                  <div class="form-group">
                    {
                      this.state.getNextWeeks.map((element) =>
                        <div class="form-check border rounded mb-1 p-1">
                          <input
                            onClick={() => {
                              var arr = [];
                              arr = this.state.copiedWeekShifts;
                              var index = arr.findIndex(x => x.weekStart === element.weekStart);
                              if (index >= 0) {
                                arr = arr.slice(0, index).concat(arr.slice(index + 1, arr.length));

                              } else {
                                arr.push({ weekStart: element.weekStart, weekEnd: element.weekEnd });
                              }
                              this.setState({ copiedWeekShifts: arr });
                              console.log(arr);
                              arr = [];
                            }}
                            class="form-check-input ml-1"
                            type="checkbox"
                            id="gridCheck" />
                          <label class="form-check-label ml-4" for="gridCheck">
                            {moment(element.weekStart).format("DD-MM-YYYY")} to {moment(element.weekEnd).format("DD-MM-YYYY")}
                          </label>
                        </div>
                      )
                    }
                  </div>
                </form>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-warning" onClick={this.handleWeekSubmit}>Paste shifts</button>
          </Modal.Footer>
        </Modal>

      </div>

    )
  }
}



{/* ME COMPONENT */ }
class DashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DatabaseData: [],
      clients: [],
      finSupport: [],
      supportServices: [],
      linkedSupportAreas: [],
      linkedSupportService: [],
      linkedFinancialPackages: [],
      clientFin: [],
      servicePrice: 0,
      serviceCost: 0,

      clientId: 0,
      packageId: 0,
      supportAreaId: 0,
      supportServicesId: 0,
      staffId: 0,
      selectedStaffId: 0,
      selectedShiftId: 0,
      weekStart: '',
      weekEnd: '',
      selectedShiftDetails: {},
      financialPackageId: 0,
      staffList: [],
      timesheets: [],
      selectedTimesheetId: [],
      userDetails: {},
      selectedOpenShiftDetails: {},
      openShifts: [],
      weekStart: "",
      weekEnd: "",

    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.editRow = this.editRow.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.setTitle = this.setTitle.bind(this);
    this.getStaff = this.getStaff.bind(this);
    this.showAddModal = this.showAddModal.bind(this);
    this.hideAddModal = this.hideAddModal.bind(this);
    this.hideOpenShiftsModal = this.hideOpenShiftsModal.bind(this);
    this.showOpenShiftsModal = this.showOpenShiftsModal.bind(this);
    this.getClients = this.getClients.bind(this);
    this.deleteShift = this.deleteShift.bind(this);
    this.calculateWeekRange = this.calculateWeekRange.bind(this);
    this.getNextWeek = this.getNextWeek.bind(this);
    this.getPreviousWeek = this.getPreviousWeek.bind(this);
    this.handleEditShift = this.handleEditShift.bind(this);
    this.handleConfirmAttendance = this.handleConfirmAttendance.bind(this);
    this.handleConfirmShift = this.handleConfirmShift.bind(this);
    this.pasteShift = this.pasteShift.bind(this);
    this.getTimesheets = this.getTimesheets.bind(this);
    this.saveAlert = this.saveAlert.bind(this);
    this.deleteAlert = this.deleteAlert.bind(this);
    this.showAddAllowanceModal = this.showAddAllowanceModal.bind(this);
    this.hideAddAllowanceModal = this.hideAddAllowanceModal.bind(this);
    this.handleAddAllowance = this.handleAddAllowance.bind(this);
    this.extractToken = this.extractToken.bind(this);
    this.confirmAlert = this.confirmAlert.bind(this);
    this.showClaimModal = this.showClaimModal.bind(this);
    this.hideClaimModal = this.hideClaimModal.bind(this);
    this.getOpenShifts = this.getOpenShifts.bind(this);
    this.handleClaimOpenShift = this.handleClaimOpenShift.bind(this);
    this.claimShiftAlert = this.claimShiftAlert.bind(this)


  }



  getOpenShifts(event) {
    var url = `https://rosterapi.tarisa.com.au/rlsss/shifts/all/openShifts?start=${event.start}&end=${event.end}&TOCKEN=${this.extractToken().TOCKEN}`;
    axios.get(url)
      .then(res => {
        var arr = [];
        res.data.forEach(element => {
          element.dayShifts.forEach(element1 => {
            arr.push(element1)
          })
        })

        this.setState({ openShifts: arr })
      }).catch(error => {


      });
  }

  handleClaimOpenShift(event) {


    const infor = {
      "staffId": Number(this.extractToken().STAFF_ID),
      "shiftId": Number(event.shiftId),
      "location": event.clientLocation,
      "Date": event.date,
      "startTime": event.startTime,
      "endTime": event.endTime,
      "notes": event.notes,
      "allowance": 0

    };





    axios.post(`https://rosterapi.tarisa.com.au/rlsss/shifts/add3?TOCKEN=${this.extractToken().TOCKEN}`, infor)
      .then(res => {
        if (res.status == 201) {

          this.claimShiftAlert();



        }

      }).catch(error => {
        swal("Not claimed!", " ", "warning")

      })



  }

  saveAlert() {
    swal("shift successifully saved!", "", "success")
      .then(() => {
        this.hideAddModal();
        this.hideAddAllowanceModal();
      })
  }
  claimShiftAlert() {
    swal("shift successifully claimed!", "", "success")
      .then(() => {
        this.hideAddModal();
        this.hideClaimModal();

        this.hideAddModal();
        this.hideAddAllowanceModal();

        this.getOpenShifts({ start: this.state.weekStart, end: this.state.weekEnd })

      })
  }

  deleteAlert() {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          swal("Poof! Your imaginary file has been deleted!", {
            icon: "success",
          });
        } else {
          swal("Your imaginary file is safe!");
        }
      })
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.name === 'isGoing' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {

    event.preventDefault();

    const infor = {
      "staffId": this.extractToken().STAFF_ID,
      "location": this.state.address,
      "Date": this.state.date,
      "startTime": this.state.startTime,
      "endTime": this.state.endTime,
      "breaking": this.state.breakMin,
      "notes": this.state.notes,
      "allowance": 0,

    };

    this.state.address = undefined
    this.state.date = undefined
    this.state.startTime = undefined
    this.state.endTime = undefined
    this.state.breakMin = undefined
    this.state.notes = undefined




    axios.post(`https://rosterapi.tarisa.com.au/rlsss/shifts/add?TOCKEN=${this.extractToken().TOCKEN}`, infor)
      .then(res => {
        if (res.status == 201) {
          this.saveAlert();
        }

        // this.hideAddModal();


      }).catch(error => {
        swal("Not saved!", " ", "warning")

      })
  }

  handleAddAllowance(event) {

    event.preventDefault();

    const infor = {
      "shiftId": Number(this.state.selectedShiftId),
      "description": this.state.description,
      "amount": this.state.amount,

    };

    this.state.amount = undefined
    this.state.description = undefined



    axios.post(`https://rosterapi.tarisa.com.au/rlsss/timeSheets/allowance/add?TOCKEN=${this.extractToken().TOCKEN}`, infor)
      .then(res => {
        if (res.status == 201) {
          this.saveAlert();
        }
        this.state.amount = undefined
        this.state.description = undefined
        // this.hideAddModal();


      }).catch(error => {
        swal("Not added!", " ", "warning")

      })
  }

  handleEditShift(event) {


    const infor = {
      "clientId": Number(this.state.clientId) == 0 ? Number(this.state.selectedShiftDetails.clientId) : Number(this.state.clientId),
      "location": this.state.address == undefined ? this.state.selectedShiftDetails.clientLocation : this.state.address,
      "Date": this.state.date == undefined ? this.state.selectedShiftDate : this.state.date,
      "startTime": this.state.startTime == undefined ? this.state.selectedShiftDetails.startTime : this.state.startTime,
      "endTime": this.state.endTime == undefined ? this.state.selectedShiftDetails.endTime : this.state.endTime,
      "breaking": this.state.breakMin == undefined ? this.state.selectedShiftDetails.breaking : this.state.breakMin,
      "notes": this.state.notes == undefined ? this.state.selectedShiftDetails.notes : this.state.notes,
      "hourlChage": this.state.serviveCost == undefined ? this.state.selectedShiftDetails.hourlChage : this.state.serviceCost,

      "supportAreaId": Number(this.state.supportAreaId) == 0 ? Number(this.state.selectedShiftDetails.supportAreaId) : Number(this.state.supportAreaId),
      "supportServiceId": Number(this.state.supportServicesId) == 0 ? Number(this.state.selectedShiftDetails.supportServiceId) : Number(this.state.supportServicesId),
      "packageId": Number(this.state.financialPackageId) == 0 ? Number(this.state.selectedShiftDetails.financialPackageId) : Number(this.state.financialPackageId)

    };




    axios.post(`https://rosterapi.tarisa.com.au/rlsss/shifts/edit/${this.state.selectedShiftId}?TOCKEN=${this.extractToken().TOCKEN}`, infor)
      .then(res => {

        this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
        this.hideModal();

      }).catch(error => {


      });
  }



  showModal() {
    this.setState({ ShowModal: true });
  }

  hideModal() {
    this.setState({ ShowModal: false });
  }
  showAddModal() {
    this.setState({ ShowAddModal: true });
  }
  showAddAllowanceModal() {
    this.setState({ ShowAddAllowanceModal: true });
  }
  hideAddAllowanceModal() {
    var url = `https://rosterapi.tarisa.com.au/rlsss/timeSheets/timeSheets/one?staffId=${this.extractToken().STAFF_ID}&start=${this.state.weekStart}&end=${this.state.weekEnd}&TOCKEN=${this.extractToken().TOCKEN}`;
    axios.get(url).then(timesheet => {

      this.setState({ timesheets: timesheet.data })


    }).catch(e => {

    })
    this.setState({ ShowAddAllowanceModal: false });
  }

  hideAddModal() {
    this.setState({ ShowAddModal: false });
  }
  hideClaimModal() {
    this.setState({ ShowClaimModal: false });
  }
  showOpenShiftsModal() {
    this.setState({ ShowOpenShiftsModal: true });
  }
  showClaimModal() {
    this.setState({ ShowClaimModal: true });
  }

  hideOpenShiftsModal() {
    this.setState({ ShowOpenShiftsModal: false });
  }

  setTitle(event) {
    this.setState({ [event.name]: event.value })


  }

  getTimesheets(event) {
    var url = `https://rosterapi.tarisa.com.au/rlsss/timeSheets/timeSheets/one?staffId=${this.extractToken().STAFF_ID}&start=${event.weekStart}&end=${event.weekEnd}&TOCKEN=${this.extractToken().TOCKEN}`;
    axios.get(url).then(timesheet => {
      this.setState({ timesheets: timesheet.data })


    }).catch(e => {

    })
  }


  calculateWeekRange() {
    let now = moment();
    var day = Number(now.weekday())
    var start = now.subtract(day, 'days').format('MM-DD-YYYY');
    var end = now.add(6, 'days').format('MM-DD-YYYY')

    this.setState({ weekStart: start, weekEnd: end })
    this.getTimesheets({ weekStart: start, weekEnd: end });
    this.getOpenShifts({ start: start, end: end });

  }

  extractToken() {
    var params = {};
    var parser = document.createElement('a');
    parser.href = window.location.href;
    var query = parser.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    this.setState({ userDetails: { username: params.USERNAME, staffId: params.STAFF_ID, accessLevel: params.ACCESS_LEVEL, tocken: params.TOCKEN } })
    {/* console.log("----------------")
    console.log(this.state.userDetails)
    console.log(params) */}
    return params
  }

  getClients() {
    var url1 = `https://rosterapi.tarisa.com.au/rlsss/shifts/clients?TOCKEN=${this.extractToken().TOCKEN}`;
    axios.get(url1).then(client => {
      this.setState({ clients: client.data });
    })

  }

  getFinSupport() {
    var url1 = `https://rosterapi.tarisa.com.au/rlsss/shifts/finsupport?TOCKEN=${this.extractToken().TOCKEN}`;
    axios.get(url1).then(client => {
      this.setState({ finSupport: client.data });
    })

  }

  getSupportServices() {
    var url1 = 'https://rosterapi.tarisa.com.au/rlsss/shifts/support-services';
    axios.get(url1).then(client => {
      this.setState({ supportServices: client.data });
    })

  }
  getClientFinancials() {
    var url1 = 'https://rosterapi.tarisa.com.au/rlsss/shifts/clientFin';
    axios.get(url1).then(client => {
      this.setState({ clientFin: client.data });
    })

  }

  deleteShift(selectedShiftId) {
    var url1 = `https://rosterapi.tarisa.com.au/rlsss/shifts/delete/${this.state.selectedShiftId}?TOCKEN=${this.extractToken().TOCKEN}`;
    axios.get(url1).then(client => {
      // this.setState({ clients: client.data });
      this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
      this.hideModal();
    })

  }

  handleConfirmAttendance(selectedShiftId) {

    this.state.selectedTimesheetId.forEach(id => {
      var url1 = `https://rosterapi.tarisa.com.au/rlsss/shifts/confirm?shiftId=${id}&TOCKEN=${this.extractToken().TOCKEN}`;
      axios.get(url1).then(client => {
        if (client.status == 200) {
          this.confirmAlert();
          this.getTimesheets({ weekStart: this.state.weekStart, weekEnd: this.state.weekEnd });

        }
      }).catch(error => {
        swal("Not confirmed!", " ", "warning")

      })
    }
    )


  }

  confirmAlert() {
    swal("Successifully confirmed!", " ", "success")
      .then(() => {
        this.hideModal();
      })
  }

  handleConfirmShift(selectedShiftId) {
    var url1 = `https://rosterapi.tarisa.com.au/rlsss/shifts/confirm?shiftId=${this.state.selectedShiftId}&TOCKEN=${this.extractToken().TOCKEN}`;
    axios.get(url1).then(client => {
      // this.setState({ clients: client.data });
      this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
      this.hideModal();
    }).catch(error => {
      swal("Not confirmed!", " ", "warning")

    })

  }

  getNextWeek() {
    var start = moment(this.state.weekEnd).format('MM-DD-YYYY');
    start = moment(start).add(1, 'days').format('MM-DD-YYYY');
    var end = moment(start).add(6, 'days').format('MM-DD-YYYY')

    this.setState({ weekStart: start, weekEnd: end })
    this.getTimesheets({ weekStart: start, weekEnd: end });

  }

  getPreviousWeek() {
    var end = moment(this.state.weekStart).format('MM-DD-YYYY');
    end = moment(end).subtract(1, 'days').format('MM-DD-YYYY');
    var start = moment(end).subtract(6, 'days').format('MM-DD-YYYY');


    this.setState({ weekStart: start, weekEnd: end })
    this.getTimesheets({ weekStart: start, weekEnd: end });

  }

  pasteShift() {


    const infor = {
      "staffId": Number(this.state.selectedStaffId),
      "clientId": Number(this.state.selectedShiftDetails.clientId),
      "location": this.state.selectedShiftDetails.clientLocation,
      "Date": this.state.date,
      "startTime": this.state.selectedShiftDetails.startTime,
      "endTime": this.state.selectedShiftDetails.endTime,
      "breaking": this.state.selectedShiftDetails.breaking,
      "notes": this.state.selectedShiftDetails.notes,
      "allowance": 0
    };




    axios.post(`https://rosterapi.tarisa.com.au/rlsss/shifts/add?TOCKEN=${this.extractToken().TOCKEN}`, infor)
      .then(res => {

        this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
        this.hideModal();

      }).catch(error => {


      });
  }

  componentDidMount() {
    this.extractToken();
    this.extractToken();
    this.calculateWeekRange();
    this.getStaff();

    this.getClients();


  }

  getStaff() {

    //FETCHING ALL STAFF FROM API
    var url1 = `https://rosterapi.tarisa.com.au/rlsss/shifts/staff?TOCKEN=${this.extractToken().TOCKEN}`;
    axios.get(url1).then(staff => {

      this.setState({ staffList: staff.data });


    }).catch(error => {

    })
  }

  editRow(props) {

  }


  render() {

    const columns = [{
      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          &#10003;
        </button>
      ),
      maxWidth: 50,
      Cell: row => {

        return (
          <div
            onClick={() => {
              var arr = this.state.selectedTimesheetId;
              if (!arr.includes(row.original.shiftId)) {
                arr = arr.concat(row.original.shiftId)
                this.setState({ selectedTimesheetId: arr })
              }


            }
            }
          >
            <input

              type="checkbox" className="ml-2">
            </input>
          </div >
        )
      }

    },
    {
      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Date
        </button>
      ),
      Cell: row => {
        var date = row.original.date;
        date = moment(date).format('llll');
        date = date.split(" ");
        return (
          <div>
            {
              date[0] + " " + date[1] + " " + date[2] + " " + date[3]
            }
          </div>
        )
      }

    }, {
      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Start time
        </button>
      ),
      accessor: 'startTime'

    }, {
      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          End time
        </button>
      ),
      accessor: 'endTime'
    }, {

      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Location
        </button>
      ),
      accessor: 'location'
    }, {

      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Duration (hours)
        </button>
      ),
      accessor: 'duration'
    }, {

      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Pay rate ($/hour)
        </button>
      ),
      accessor: 'payRate'
    }, {

      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Allowances ($)
        </button>
      ),
      accessor: 'allowance'
    }, {

      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Status
        </button>
      ),
      minWidth: 150,
      Cell: row => {

        return (
          <div>
            {
              row.original.isApproved ? <a className="label label-info"><small><span className="badge badge-success ml-2">Approved</span></small></a> : <a className="label label-info"><small><span className="badge badge-danger ml-2">Not approved</span></small></a>
            }
            {
              row.original.isCompleted ? <a className="label label-info"><small><span className="badge badge-warning ml-2">Completed</span></small></a> : <a className="label label-info"><small><span className="badge badge-danger ml-2">Uncompleted</span></small></a>
            }
            {
              row.original.isAdminConfirmed ? <a className="label label-info"><small><span className="badge badge-info ml-2">Admin confirmed</span></small></a> : <a className="label label-info"><small><span className="badge badge-danger ml-2">Unconfirmed</span></small></a>
            }
          </div>
        )
      }
    }, {

      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Actions
        </button>
      ),
      Cell: row => {

        return (
          <div>
            {
              <button
                onClick={() => {
                  this.setState({ selectedShiftId: row.original.shiftId })
                  this.showAddAllowanceModal()
                }
                }
                type="button"
                className="btn btn-sm btn-outline-success btn-block mr-2"
              >
                &#43; Add allowance
          </button>
            }
          </div>
        )
      }
    }]

    const columns2 = [
      {
        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            Date
          </button>
        ),
        accessor: 'date'

      }, {
        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            Start time
          </button>
        ),
        accessor: 'startTime'

      }, {
        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            End time
          </button>
        ),
        accessor: 'endTime'
      }, {

        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            Location
          </button>
        ),
        accessor: 'clientLocation'

      }, {

        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            Actions
          </button>
        ),
        Cell: row => {

          return (
            <div>
              {

                <button
                  onClick={() => {
                    this.handleClaimOpenShift(row.original)
                  }
                  }
                  type="button"
                  className="btn btn-sm btn-outline-success btn-block mr-2"
                >
                  Claim shift
          </button>
              }
            </div>
          )
        }
      }]

    return (
      <div>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 ml-2 mr-2">
          <h1 className="h2">Me</h1>
          <div className="btn-toolbar mb-2 mb-md-0">
            <div className="btn-group mr-2">
              <button onClick={this.getPreviousWeek} type="button" className="btn btn-sm btn-outline-secondary">&#10094;</button>
              <button type="button" className="btn btn-sm btn-outline-secondary">
                <span data-feather="calendar"></span>{moment(this.state.weekStart).format('DD-MM-YYYY')} - {moment(this.state.weekEnd).format('DD-MM-YYYY')}</button>
              <button onClick={this.getNextWeek} type="button" className="btn btn-sm btn-outline-secondary">&#10095;</button>
            </div>

            <button
              onClick={() => {
                this.getOpenShifts({ start: this.state.weekStart, end: this.state.weekEnd });
                this.showClaimModal();
              }
              }
              type="button"
              className="btn btn-sm btn-outline-secondary mr-2"
            >
              Claim open shift(s)
          </button>
            <button
              onClick={this.showAddModal}
              type="button"
              className="btn btn-sm btn-outline-secondary mr-2"
            >
              &#43; Add shift
          </button>
            <button
              type="button"
              onClick={this.handleConfirmAttendance}
              className="btn btn-sm btn-outline-secondary mr-2"
            > &#10003; Confirm completed timesheets
          </button>
            <button
              type="button"
              className="btn btn-sm btn-outline-secondary mr-2"
            >

              {this.state.timesheets.length} available shifts

          </button>

          </div>
        </div>
        <div className="form-row">
          <div className="col-md-12">
            <ReactTable
              data={this.state.timesheets}
              columns={columns}
              defaultPageSize={10}
            />
          </div>

          {/* CLAIM SHIFT MODAL */}
          <Modal show={this.state.ShowClaimModal} onHide={true} size="lg" >
            <Modal.Header>
              <Modal.Title><h5 className="modal-title" >Claim shift(s)</h5></Modal.Title>
              <button type="button" className="close" onClick={this.hideClaimModal}>
                <span aria-hidden="true">&times;</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className="row">

                <div className="col-md-12">
                  <ReactTable
                    data={this.state.openShifts}
                    columns={columns2}
                    defaultPageSize={10}
                  />
                </div>

              </div>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
          </Modal>
          {/* ADD SHIFT MODAL */}
          <Modal show={this.state.ShowAddModal} onHide={true} size="lg" >
            <form onSubmit={this.handleSubmit}>
              <Modal.Header>
                <Modal.Title><h5 className="modal-title" >Add shift</h5></Modal.Title>
                <button type="button" className="close" onClick={this.hideAddModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </Modal.Header>
              <Modal.Body>
                <div className="row">

                  <div className="col-md-12">



                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="inputAddress">Location</label>
                        <input required type="text" className="form-control" name="address" onChange={event => this.setTitle(event.target)} placeholder="Location" />

                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="inputAddress">Date</label>
                        <input required type="date" className="form-control" name="date" onChange={event => this.setTitle(event.target)} />

                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label htmlFor="inputCity">Start time</label>
                        <input required type="time" className="form-control" onChange={event => this.setTitle(event.target)} name="startTime" />

                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="inputCity">End time</label>
                        <input required type="time" className="form-control" onChange={event => this.setTitle(event.target)} name="endTime" />

                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="inputCity">Break(min)</label>
                        <input type="number" className="form-control" onChange={event => this.setTitle(event.target)} name="breakMin" />

                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleFormControlTextarea1">Notes</label>
                      <textarea  value={this.state.notes} className="form-control" onChange={event => this.setTitle(event.target)} name="notes" rows="3"></textarea>

                    </div>

                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-warning"
                  type="Submit">Save shift</button>
              </Modal.Footer>
            </form>
          </Modal>

          {/* ADD ALLOWANCE MODAL */}

          <Modal show={this.state.ShowAddAllowanceModal} onHide={true} size="lg" >
            <form onSubmit={this.handleAddAllowance}>
              <Modal.Header>
                <Modal.Title><h5 className="modal-title" >Add Allowance</h5></Modal.Title>
                <button type="button" className="close" onClick={this.hideAddAllowanceModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12">

                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label htmlFor="inputCity">Amount</label>
                        <input required type="number" className="form-control" onChange={event => this.setTitle(event.target)} name="amount" />


                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleFormControlTextarea1">Description</label>
                      <textarea required className="form-control" onChange={event => this.setTitle(event.target)} name="description" rows="3"></textarea>


                    </div>

                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-warning"
                  type="Submit"
                >Add allowance</button>
              </Modal.Footer>
            </form>
          </Modal>

        </div>

      </div >
    )
  }
}
